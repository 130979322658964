// import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Users from './Users'
// import { MdClose } from "react-icons/md";
// import { setAuthVariable, logout } from '../../redux/actions/authActions'
// import * as helpers from '../../helpers/helpers'

function mapStateToProps(state) {
  return {
    // view: state.auth.view,
    // questionList: state.users.questionList,
    // user: state.auth.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // setAuthVariable: (fieldname, value) => {
    //   dispatch(setAuthVariable(fieldname, value))
    // },
    // logout: () => {
    //   dispatch(logout())
    // }
  }
}

class Banner extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // signInClick = () => {
  //   this.props.setAuthVariable('page', 'sign_in')
  // }

  // continueClick = () => {
  //   this.props.setAuthVariable('page', 'join_email')
  // }

  // viewAccountPageClick = () => {
  //   this.props.setAuthVariable('viewAccountPage', false)
  // }

  render() {
    // const { user, logout } = this.props

    return (
      <div className={'banner'}>
        <div>Your feedback makes a difference. Please give us yours! <a href={'https://forms.gle/dcim1eiayPCePkpk7'} target={'_blank'} className={'link_white'}>Click Here</a></div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Banner)
