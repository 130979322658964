import React, { Component } from 'react'
import { connect } from 'react-redux'
import NewSalesforceConnector from './NewSalesforceConnector'
// import people2 from '../../assets/lady_dashboard_man_computer.svg'
import {
  setAuthVariable
} from '../../redux/actions/authActions'

function mapStateToProps(state) {
  return {
    view: state.auth.view,
    company: state.auth.company
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    }
  }
}

class NoConnector extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onClose = () => {
    this.props.setAuthVariable('addNewConnector', false)
  }

  render() {
    // const { company } = this.props

    return (
      // <div className={'center'}>
      <div>
      
        {/* <div className={'card'}> */}
          <br></br>
          {/* <h2>
            It looks like you don't have any connector for {company.name} yet.
          </h2> */}
          <NewSalesforceConnector />
          <br></br>
        {/* </div> */}
        {/* <img src={people2} height="300" alt="logo" /> */}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoConnector)
