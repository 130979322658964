import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  setUsersVariable,
  submitNewConnector
} from '../../redux/actions/usersActions';

function mapStateToProps(state) {
  return {
    view: state.auth.view,
    company: state.auth.company,

    connectorName: state.users.connectorName,
    connectorCode: state.users.connectorCode,
    connectorError: state.users.connectorError,
    connectorBank: state.users.connectorBank,

    submittingNewConnector: state.auth.submittingNewConnector,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setUsersVariable: (fieldname, value) => {
      dispatch(setUsersVariable(fieldname, value))
    },
    submitNewConnector: () => {
      dispatch(submitNewConnector())
    }
  }
}

class NewSalesforceConnector extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onSubmit = (event) => {
    this.props.submitNewConnector();
    event.preventDefault();
  }

  onNameChange = (event) => {
    this.props.setUsersVariable('connectorName', event.target.value)
  }

  onCodeChange = (event) => {
    if(this.props.connectorError) {
      this.props.setUsersVariable('connectorError', '')  
    }
    this.props.setUsersVariable('connectorCode', event.target.value.toUpperCase())
  }
  
  chooseQuestionBank = (bank) => {
    this.props.setUsersVariable('connectorBank', bank)
  }

  render() {
    const { company, connectorName, connectorCode, connectorError, submittingNewConnector } = this.props

    let disabled = true;
    if(connectorName && connectorCode) {
      disabled = false;
    }

    return (
      <div className={'left'}>
        <h2>New Salesforce connector for {company.name}</h2>
        <br></br>
        <h3>Here's how:</h3>
        <br></br>
        <form onSubmit={this.onSubmit}>
          <div>1. Enter a user friendly name to call your connector</div>
          <input
            value={connectorName}
            type='text'
            placeholder='Name'
            name='connector_name'
            onChange={this.onNameChange}
          />
          <br></br>
          <br></br>
          <div>2. Download the Poll Bridge Salesforce App. You can access it here.</div>
          <br></br>
          <a className={'link'}
            href={'https://login.salesforce.com/packaging/installPackage.apexp?p0=04t3j000000fexh'}
            target={'_blank'}
          >https://login.salesforce.com/packaging/installPackage.apexp?p0=04t3j000000fexh</a>
          <br></br>
          <br></br>
          <div>3. Follow the instructions on the settings page.</div>
          <br></br>
          <div>4. Enter the code found in the Salesforce app's settings page that you are connecting.</div>
          <input
            value={connectorCode}
            type='text'
            placeholder='Code'
            name='connector_code'
            onChange={this.onCodeChange}
          />
          <br></br>
          <br></br>
          {/* <div>5. Pick CGAPS or PRIDE (so we can download the correct question bank)</div>
          <br></br>
          <div>PRIDE is for a good description here</div>
          <div>CGAPS is for a good description here</div>
          <br></br>
          <button className={`bank_toggle ${connectorBank === 'pride' ? 'bank_toggle_active' : '' }`} onClick={() => this.chooseQuestionBank('pride')}>
            PRIDE
          </button>
          <button className={`bank_toggle ${connectorBank === 'cgaps' ? 'bank_toggle_active' : '' }`} onClick={() => this.chooseQuestionBank('cgaps')}>
            CGAPS
          </button>
          <br></br>
          <br></br> */}
          {connectorError ? (
            <div className={'error'}>{connectorError}
            <br></br>
            <br></br>
            </div>
          ):null}
          <button type='submit' className={'green_button'} disabled={disabled}>
            {submittingNewConnector ? 'Loading...' : 'create connector'}
          </button>
        </form>

        {/* id: ID! */}
        {/* type: String */}

        {/* status: String */}
        {/* timestamp: Int */}
        {/* company: Company @connection(name: "CompanyConnector") */}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewSalesforceConnector)
