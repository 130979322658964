import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as helper from '../../helpers/helpers';

import {
  setUsersVariable,
  sendInvite
} from '../../redux/actions/usersActions';

function mapStateToProps(state) {
	return {
    inviteEmail: state.users.inviteEmail,
    sendingInvite: state.auth.sendingInvite
	};
}

function mapDispatchToProps(dispatch) {
	return {
    setUsersVariable: (fieldname, value) => {
			dispatch(setUsersVariable(fieldname, value))
    },
    sendInvite: (email) => {
      dispatch(sendInvite(email));
    }
	}
}

class InviteUserManually extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  onEmailChange = (event) => {
		this.props.setUsersVariable('inviteEmail', event.target.value);
  }

  onInviteUserManually = (event) => {
    const { sendInvite, inviteEmail } = this.props;
    sendInvite(inviteEmail);
    event.preventDefault();
  }
  
  render() {
    const { inviteEmail, sendingInvite } = this.props;
    const emailValid = helper.validateEmail(inviteEmail);
		let disabled = true;
    if (inviteEmail && emailValid) {
			disabled = false;
		}
    return (
      <div>
        <form onSubmit={this.onInviteUserManually}>
          <input value={inviteEmail} 
            className={'margin_right_10'}
            type="email" placeholder='Email' name="email" 
            onChange={this.onEmailChange} />
          <button type="submit" className={'cta_alt'} disabled={disabled}>
            {sendingInvite ? 'Loading...' : 'invite'}
          </button>
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserManually);