import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/sidebar.css';
import './styles/dashboard.css';
import './styles/test.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister();










