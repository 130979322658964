// import * as helper from '../../helpers/helpers'
import * as queries from '../../graphql/queries'
// import * as mutations from '../../graphql/mutations'
// import { runQuery, runMutation } from './gqlHelper'
import { runQuery } from './gqlHelper'

export async function getTeamMembersByConnectorId(connectorId) {
  let teamMembers = []
  let body = {
    connectorId: connectorId
  }
  let more = true
  while (more) {
    more = false
    const resultObj = await runQuery(queries.teamMembersByConnectorId, body)
    const result = resultObj.data.teamMembersByConnectorId
    teamMembers = teamMembers.concat(result.items)
    if (result.nextToken) {
      more = true
      body.nextToken = result.nextToken
    }
  }
  teamMembers.sort((a, b) => {
    if (a.email.toLowerCase() > b.email.toLowerCase()) {
      return 1
    } else {
      return -1
    }
  })
  return teamMembers
}

export function formatNewTeamMembersMap(teamMembers) {
  let map = {}
  for (let member of teamMembers) {
    if (!map[member.teamId]) {
      map[member.teamId] = []
    }
    map[member.teamId].push(member)
  }
  return map
}
