import _ from 'lodash'
// import * as helper from '../../helpers/helpers'
import * as queries from '../../graphql/queries'
// import * as mutations from '../../graphql/mutations'
// import { runQuery, runMutation } from './gqlHelper'
import {runQuery} from './gqlHelper'

export async function getAllAnswersForTeamQuestion(teamQuestionId) {
  try {
    let d = new Date()
    d.setDate(d.getDate() - 90)
    let time = Math.floor(d / 1000)
    let body = {
      teamQuestionId,
      // limit: 5,
      filter: {
        timestamp: {
          gt: time
        }
      }
    }
    let answers = []
    let more = true
    while (more) {
      more = false
      const resultObj = await runQuery(queries.answerByTeamQuestionId, body)
      const result = resultObj.data.answerByTeamQuestionId
      answers = answers.concat(result.items)
      if (result.nextToken) {
        more = true
        body.nextToken = result.nextToken
      }
    }
    console.log(answers)
    return answers
  } catch (error) {
    console.log(error)
    return []
  }
}


////////////////////////////////////////////

export function calculateAllAverages(answers, teamQuestions) {
  let averageByCategory = formatAverageByCategory(
    answers,
    teamQuestions
  )
  let sortedAverages = averageByCategory.sort(function (a, b) {
    return a.average - b.average
  })
  let lowestScores = sortedAverages.slice(0, 5)
  let highestScores = _.takeRight(sortedAverages, 5)
  let total = 0
  for (let row of sortedAverages) {
    let aveFloat = parseFloat(row.average)
    total += aveFloat
  }
  let overallAverage = (total / sortedAverages.length).toFixed(2)
  let averagesByCategory = formatAveragesByCategory(
    sortedAverages
  )
  return {
    sortedAverages,
    lowestScores,
    highestScores: highestScores.reverse(),
    overallAverage,
    averagesByCategory
  }
}


export function formatAverageByCategory(answers, teamQuestions) {
  let response = []
  let questionMap = {}
  for (let question of teamQuestions) {
    questionMap[question.id] = {
      id: question.id,
      text: question.question.text,
      answers: [],
      category: question.question.category,
      average: '0.00'
    }
  }
  for (let answer of answers) {
    // console.log(answer.weight, answer.id);
    // console.log(answer.weight)
    questionMap[answer.teamQuestionId].answers.push(answer.weight)
  }
  for (let key in questionMap) {
    // console.log(key);
    // console.log(questionMap[key].answers)
    // console.log(average(questionMap[key].answers))
    if (questionMap[key].answers.length > 0) {
      let average = calculateAverage(questionMap[key].answers).toFixed(2)
      questionMap[key].average = average
      if (average >= 70.0) {
        questionMap[key].class = 'good_average'
      } else if (average <= 40.0) {
        questionMap[key].class = 'bad_average'
      } else {
        questionMap[key].class = 'neutral_average'
      }
      response.push(questionMap[key])
    } else {
      // delete questionMap[key];
    }
  }
  return response
}

export function calculateAverage(arr) {
  if (arr.length === 0) {
    return 0
  }
  return arr.reduce((a, b) => a + b, 0) / arr.length
}

export function formatAveragesByCategory(sortedAverages) {
  let categories = {}
  for (let row of sortedAverages) {
    let value = parseFloat(row.average)
    if (categories[row.category]) {
      categories[row.category].push(value)
    } else {
      categories[row.category] = []
      categories[row.category].push(value)
    }
  }
  let arr = []
  for (let key in categories) {
    let average = calculateAverage(categories[key])
    let className = 'neutral_average'
    if (average >= 3.33) {
      className = 'good_average'
    } else if (average <= 1.66) {
      className = 'bad_average'
    }
    arr.push({
      category: key,
      average: average.toFixed(2),
      class: className
    })
  }
  return arr.sort(function (a, b) {
    return b.average - a.average
  })
}
