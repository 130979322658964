import _ from 'lodash'
import * as a from '../constants/actions'
import * as authActions from './authActions'

import * as helper from '../../helpers/helpers'
import * as teamHelper from '../helpers/teamHelper'
import * as answerHelper from '../helpers/answerHelper'
import * as inviteHelper from '../helpers/inviteHelper'
import * as companyHelper from '../helpers/companyHelper'
import * as fakeDataHelper from '../helpers/fakeDataHelper'
import * as questionHelper from '../helpers/questionHelper'
import * as connectorHelper from '../helpers/connectorHelper'
import * as teamMemberHelper from '../helpers/teamMemberHelper'
import * as questionBankHelper from '../helpers/questionBankHelper'
import * as teamQuestionHelper from '../helpers/teamQuestionHelper'

export function setUsersVariable(fieldname, value) {
    return { type: a.SET_USERS_VARIABLE, payload: { fieldname, value } }
}

export function setUsersVariables(object) {
    return { type: a.SET_USERS_VARIABLES, payload: object }
}

export function loadEverythingForInvite(invite) {
    // console.log('THIS IS THE MAIN ENTRANCE POINT')
    return async function(dispatch, getState) {
        const company = await companyHelper.getCompanyById(invite.companyId)
        dispatch(
            authActions.setAuthVariables({
                currentInvite: invite,
                permissionGroup: invite.group,
                company: company
            })
        )
        dispatch(loadConnectorsForInvite(invite, true))
        dispatch(loadInviteListForCompany(company))
    }
}

export function sendInvite(email) {
    return async function(dispatch, getState) {
        const company = getState().auth.company
        dispatch(authActions.setAuthVariable('sendingInvite', true))
        try {
            let invite = await inviteHelper.findInviteForUser(email, company.id)
            if (invite) {
                // update
                invite.revoked = false
                await inviteHelper.updateInvite(invite)
            } else {
                // create new
                await inviteHelper.createNewMemberInvite(email, company.id)
            }
            dispatch(loadInviteListForCompany(company))
        } catch (error) {
            console.log(error)
            dispatch(authActions.setAuthVariable('sendingInvite', false))
        }
    }
}

export function revokeInvite(invite) {
    return async function(dispatch, getState) {
        const company = getState().auth.company
        try {
            await inviteHelper.revokeInvite(invite)
            dispatch(loadInviteListForCompany(company))
        } catch (error) {
            console.log(error)
        }
    }
}

export function updateInviteGroup(invite, group) {
    return async function(dispatch, getState) {
        const company = getState().auth.company
        try {
            await inviteHelper.updateInviteGroup(invite, group)
            dispatch(loadInviteListForCompany(company))
        } catch (error) {
            console.log(error)
        }
    }
}

export function addConnectorToInvite(invite, connector) {
    return async function(dispatch, getState) {
        dispatch(setUsersVariable('grantingAccessId', invite.id))
        try {
            let actualInvite = await inviteHelper.getInviteById(invite.id)
            let connectorList = _.cloneDeep(actualInvite.connectors)
            if (!connectorList) {
                connectorList = []
            }
            let matchFound = false
            for (let c of connectorList) {
                if (c === connector.id) {
                    matchFound = true
                }
            }
            if (!matchFound) {
                connectorList.push(connector.id)
            }
            await inviteHelper.updateInviteConnectorList(actualInvite, connectorList)
            dispatch(loadInvitesForConnector(connector))
        } catch (error) {
            dispatch(setUsersVariable('grantingAccessId', ''))
            console.log(error)
        }
    }
}

export function removeConnectorFromInvite(invite, connector) {
    return async function(dispatch, getState) {
        dispatch(setUsersVariable('revokingAccessId', invite.id))
        try {
            let actualInvite = await inviteHelper.getInviteById(invite.id)
            let connectorList = _.cloneDeep(actualInvite.connectors)
            if (!connectorList) {
                connectorList = []
            }
            let matchFound = false
            let ids = []
            for (let c of connectorList) {
                if (c === connector.id) {
                    matchFound = true
                } else {
                    ids.push(c)
                }
            }
            if (!matchFound) {
                console.log('revoke connector to invite error')
                return
            }
            await inviteHelper.updateInviteConnectorList(actualInvite, ids)
            dispatch(loadInvitesForConnector(connector))
        } catch (error) {
            dispatch(setUsersVariable('revokingAccessId', ''))
            console.log(error)
        }
    }
}

export function loadConnectorsForInvite(invite, initialLoad) {
    return async function(dispatch, getState) {
        const connectors = await connectorHelper.getConnectorsById(
            invite.connectors
        )
        if (connectors.length > 0 && initialLoad) {
            dispatch(authActions.setAuthVariable('connector', connectors[0]))
            dispatch(loadEverythnigForConnector(connectors[0]))
        }
        dispatch(
            setUsersVariables({
                connectorList: connectors,
                connectorName: '',
                connectorCode: ''
            })
        )
        dispatch(
            authActions.setAuthVariables({
                connectorsLoaded: true,
                addNewConnector: false,
                submittingNewConnector: false
            })
        )
    }
}

export function loadInviteListForCompany(company) {
    return async function(dispatch, getState) {
        const invites = await inviteHelper.getInvitesByCompanyId(company.id)
        dispatch(
            setUsersVariables({
                inviteList: invites,
                inviteEmail: ''
            })
        )
        dispatch(authActions.setAuthVariable('sendingInvite', false))
    }
}

export function loadEverythnigForConnector(connector) {
    return async function(dispatch, getState) {
        dispatch(loadTeamsForConnector(connector))
        dispatch(loadInvitesForConnector(connector))
        dispatch(loadTeamMembersForConnector(connector))
    }
}

export function loadTeamsForConnector(connector) {
    return async function(dispatch, getState) {
        let teamMap = getState().users.teamMap
        dispatch(
            setUsersVariables({
                teamList: [],
                answerList: [],
                questionList: []
            })
        )
        try {
            let teams = []
            if (teamMap[connector.id] && teamMap[connector.id].length > 0) {
                teams = teamMap[connector.id]
            } else {
                teams = await teamHelper.getTeamsByConnectorId(connector.id)
            }
            teams.sort(helper.sortAlphabeticallyByName)
            teamMap[connector.id] = teams
            if (teams.length > 0) {
                dispatch(loadEverythingForTeam(teams[0]))
                dispatch(
                    authActions.setAuthVariables({
                        team: teams[0],
                        addNewTeam: false
                    })
                )
            }
            dispatch(
                setUsersVariables({
                    teamList: teams,
                    teamMap: teamMap
                })
            )
        } catch (error) {
            console.log(error)
        }
    }
}

export function loadInvitesForConnector(connector) {
    return async function(dispatch, getState) {
        try {
            const invites = await inviteHelper.getInvitesByConnectorId(connector.id)
            dispatch(
                setUsersVariables({
                    connectorInvites: invites,
                    grantingAccessId: '',
                    revokingAccessId: ''
                })
            )
        } catch (error) {
            console.log(error.message)
        }
    }
}

export function loadTeamMembersForConnector(connector) {
    return async function(dispatch, getState) {
        debugger
        try {
            const teamMembers = await teamMemberHelper.getTeamMembersByConnectorId(
                connector.id
            )
            const newTeamMembersMap = teamMemberHelper.formatNewTeamMembersMap(
                teamMembers
            )
            debugger
            dispatch(
                setUsersVariables({
                    teamMembers: teamMembers,
                    newTeamMembersMap: newTeamMembersMap
                })
            )
        } catch (error) {
            console.log(error)
            console.log(error.message)
        }
    }
}

export function updateOrCreateTeam() {
    return async function(dispatch, getState) {
        const team = getState().auth.team
        const teamList = getState().users.teamList
        const newTeamList = _.cloneDeep(teamList)
        const company = getState().auth.company
        const connector = getState().auth.connector
        const newTeamMembersMap = getState().users.newTeamMembersMap
        const addNewTeam = getState().auth.addNewTeam
        const members = newTeamMembersMap[team.id] || []
        const teamMembers = getState().users.teamMembers
        // name will come from team.newName if exists and is different from team.name
        let name = team.name
        let newName = false
        if (team.newName && team.newName !== team.name) {
            name = team.newName
            newName = true
        }

        let newTeam = team
        if (addNewTeam) {
            // CREATE NEW TEAM
            newTeam = await teamHelper.createNewTeam(company.id, connector.id, name)
            newTeamList.push(newTeam)
            newTeamList.sort(helper.sortAlphabeticallyByName)
            dispatch(setUsersVariable('teamList', newTeamList))
            // Do we want to connect questions to the this team?
            dispatch(loadEverythingForTeam(newTeam))
            dispatch(authActions.setAuthVariable('team', newTeam))
            dispatch(authActions.setAuthVariable('addNewTeam', false))
        } else {
            // UPDATE TEAM
            if (newName) {
                for (let t of newTeamList) {
                    if (t.id === team.id) {
                        t.name = name
                    }
                }
                // console.log('-=-=-=-=-=-=-')
                // console.log(team)
                newTeam = await teamHelper.updateTeamName(team, name)
                // console.log(newTeam)
                newTeamList.sort(helper.sortAlphabeticallyByName)
                dispatch(setUsersVariable('teamList', newTeamList))
                // TODO update team list with new name
            }
        }
        await teamHelper.updateTeamMembers(newTeam, teamList, members, teamMembers)
        dispatch(loadTeamMembersForConnector(connector))
    }
}

export function loadEverythingForTeam(team, reload) {
    return async function(dispatch, getState) {
        dispatch(loadTeamQuestions(team, reload))
        dispatch(loadTeamQuestionBanks(team, reload))
    }
}

export function loadTeamQuestions(team, reload) {
    return async function(dispatch, getState) {
        dispatch(authActions.setAuthVariable('loadingQuestions', true))
        let questionMap = getState().users.questionMap
        let teamQuestions = []
        try {
            if (!reload && questionMap[team.id] && questionMap[team.id].length > 0) {
                teamQuestions = questionMap[team.id]
            } else {
                teamQuestions = await teamQuestionHelper.getTeamQuestionsByTeamId(
                    team.id
                )
            }
            questionMap[team.id] = teamQuestions

            dispatch(
                setUsersVariables({
                    questionMap: questionMap,
                    questionList: teamQuestions
                })
            )
            dispatch(loadTeamAnswers(team, teamQuestions))
            dispatch(
                authActions.setAuthVariable({
                    uninstallingBank: false,
                    downloadingBank: false
                })
            )
        } catch (error) {
            console.log(error)
            dispatch(
                authActions.setAuthVariable({
                    uninstallingBank: false,
                    downloadingBank: false,
                    loadingQuestions: false
                })
            )
        }
    }
}

export function updateOrCreateBank(bank, questions) {
    return async function(dispatch, getState) {
        const team = getState().auth.team
        const company = getState().auth.company
        try {
            if (bank.id) {
                // update
                let updatedBank = await questionBankHelper.updateBank(bank)
                await questionBankHelper.createNewQuestionsForBank(
                    questions,
                    updatedBank,
                    team
                )
            } else {
                // create
                let newBank = await questionBankHelper.createNewBank(
                    bank,
                    team,
                    company
                )
                await questionBankHelper.createNewQuestionsForBank(
                    questions,
                    newBank,
                    team
                )
            }
            dispatch(loadTeamQuestions(team, true))
            dispatch(loadQuestionsForBank(bank))
            dispatch(loadTeamQuestionBanks(team, true))
        } catch (error) {
            console.log(error)
        }
    }
}

export function loadTeamQuestionBanks(team, reload) {
    return async function(dispatch, getState) {
        const bankListMap = getState().users.bankListMap
        const company = getState().auth.company
        try {
            let bankList = []
            if (!reload && bankListMap[team.id]) {
                bankList = bankListMap[team.id]
            } else {
                bankList = await questionBankHelper.getPublicTeamCompanyBanks(
                    team.id,
                    company.id
                )
                bankListMap[team.id] = bankList
            }
            dispatch(
                setUsersVariables({
                    bankList: bankList,
                    bankListMap: bankListMap
                })
            )
        } catch (error) {
            console.log(error)
        }
    }
}

export function loadQuestionsForBank(bank) {
    return async function(dispatch, getState) {
        dispatch(authActions.setAuthVariable('loadingBankQuestions', true))
        const bankQuestionListMap = getState().users.bankQuestionListMap
        try {
            let questions = await questionHelper.getQuestionsByBankId(bank.id)
            bankQuestionListMap[bank.id] = questions
            dispatch(
                setUsersVariables({
                    bankQuestionList: questions,
                    bankQuestionListMap: bankQuestionListMap
                })
            )
            dispatch(authActions.setAuthVariable('loadingBankQuestions', false))
        } catch (error) {
            console.log(error)
            dispatch(authActions.setAuthVariable('loadingBankQuestions', false))
        }
    }
}

export function loadTeamAnswers(team, teamQuestions) {
    return async function(dispatch, getState) {
        try {
            dispatch(authActions.setAuthVariable('loadingAnswers', true))
            dispatch(authActions.setAuthVariable('loadingQuestions', false))
            let answerMap = getState().users.answerMap
            let answers = []
            if (answerMap[team.id]) {
                answers = answerMap[team.id]
            } else {
                let promises = []
                for (let question of teamQuestions) {
                    promises.push(answerHelper.getAllAnswersForTeamQuestion(question.id))
                }
                let results = await Promise.all(promises)
                for (let result of results) {
                    answers = answers.concat(result)
                }
                answers.sort((a, b) => {
                    if (a.timestamp > b.timestamp) {
                        return -1
                    } else {
                        return 1
                    }
                })
                answerMap[team.id] = answers
            }
            let averages = answerHelper.calculateAllAverages(answers, teamQuestions)
            dispatch(
                setUsersVariables({
                    answerMap: answerMap,
                    answerList: answerMap[team.id],
                    sortedAverages: averages.sortedAverages,
                    lowestScores: averages.lowestScores,
                    highestScores: averages.highestScores,
                    overallAverage: averages.overallAverage,
                    averagesByCategory: averages.averagesByCategory
                })
            )
            dispatch(authActions.setAuthVariable('loadingAnswers', false))
        } catch (error) {
            console.log(error)
            dispatch(authActions.setAuthVariable('loadingAnswers', false))
        }
    }
}

export function submitNewConnector() {
    return async function(dispatch, getState) {
        const company = getState().auth.company
        const connectorName = getState().users.connectorName
        const connectorCode = getState().users.connectorCode
        const currentInvite = getState().auth.currentInvite
        dispatch(authActions.setAuthVariable('submittingNewConnector', true))
        try {
            let connectors = await connectorHelper.getConnectorsByCode(connectorCode)
            if (connectors.length > 0) {
                let message =
                    'A connector with that code already exists. If you are sure the code you are entering is correct, please contact support.'
                dispatch(setUsersVariable('connectorError', message))
                dispatch(authActions.setAuthVariable('submittingNewConnector', false))
            } else {
                const newConnector = await connectorHelper.createSalesforceConnector(
                    connectorName,
                    connectorCode,
                    company.id
                )
                // add new connector id to invite, load connectors
                dispatch(authActions.setAuthVariable('connector', newConnector))
                dispatch(addConnectorToInvite(currentInvite, newConnector))
                dispatch(createGeneralTeam(newConnector))
            }
        } catch (error) {
            console.log(error)
            dispatch(
                setUsersVariable(
                    'connectorError',
                    'Something went wrong. Please try again later.'
                )
            )
            dispatch(authActions.setAuthVariable('submittingNewConnector', false))
        }
    }
}

export function createGeneralTeam(connector) {
    return async function(dispatch, getState) {
        const company = getState().auth.company
        try {
            const team = await teamHelper.createGeneralTeam(company.id, connector.id)

            if (team) {
                dispatch(authActions.setAuthVariable('team', team))
                dispatch(loadEverythingForTeam(team))
                dispatch(setUsersVariable('teamList', [team]))
                let bank = {
                    id: 'e7698be9-06e7-4feb-a266-7eb48db189f4'
                }
                dispatch(downloadBankToTeam(team, bank))
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function uninstallBankFromTeam(team, bank) {
    return async function(dispatch, getState) {
        try {
            dispatch(authActions.setAuthVariable('uninstallingBank', true))
            let teamQuestions = await teamQuestionHelper.getTeamQuestionsByTeamId(
                team.id
            )
            let teamQuestionsToDeactivate = []
            for (let teamQuestion of teamQuestions) {
                if (teamQuestion.question.questionBankId === bank.id) {
                    teamQuestion.active = false
                    teamQuestion.nextAskDate = 0
                    teamQuestionsToDeactivate.push(teamQuestion)
                }
            }
            await teamQuestionHelper.updateTeamQuestions(teamQuestionsToDeactivate)
            dispatch(loadEverythingForTeam(team, true))
        } catch (error) {
            console.log(error.message)
            dispatch(authActions.setAuthVariable('uninstallingBank', false))
        }
    }
}

export function downloadBankToTeam(team, bank) {
    return async function(dispatch, getState) {
        try {
            dispatch(authActions.setAuthVariable('downloadingBank', true))
            let questions = await questionHelper.getQuestionsByBankId(bank.id)
            let teamQuestions = await teamQuestionHelper.getTeamQuestionsByTeamId(
                team.id
            )
            let teamQuestionsToActivate = []
            let teamQuestionsToCreate = []
            for (let question of questions) {
                let found = false
                for (let teamQuestion of teamQuestions) {
                    if (question.id === teamQuestion.question.id) {
                        // console.log('update')
                        teamQuestion.active = true
                        teamQuestionsToActivate.push(teamQuestion)
                        found = true
                    }
                }
                if (!found) {
                    // console.log('new team question')
                    question.active = true
                    teamQuestionsToCreate.push(question)
                }
            }
            await teamQuestionHelper.updateTeamQuestions(teamQuestionsToActivate)
            await teamQuestionHelper.createTeamQuestions(teamQuestionsToCreate, team)
            dispatch(loadEverythingForTeam(team, true))
        } catch (error) {
            console.log(error.message)
            dispatch(authActions.setAuthVariable('downloadingBank', false))
        }
    }
}

export function updateScheduleOrder(questions) {
    return async function(dispatch, getState) {
        try {
            const team = getState().auth.team
            await teamQuestionHelper.updateTeamQuestions(questions)
            dispatch(loadEverythingForTeam(team))
        } catch (error) {
            console.log(error)
        }
    }
}

export function toggleQuestionActive(question) {
    return async function(dispatch, getState) {
        try {
            const team = getState().auth.team
            const questionList = getState().users.questionList
            let questionListCopy = _.cloneDeep(questionList)
            let questionMap = getState().users.questionMap
            if (question.active) {
                question.active = false
            } else {
                question.active = true
            }
            await teamQuestionHelper.updateTeamQuestion(question)
            for (let q of questionListCopy) {
                if (question.id === q.id) {
                    q.active = question.active
                }
            }
            questionMap[team.id] = questionListCopy
            dispatch(setUsersVariable('questionMap', questionMap))
            dispatch(setUsersVariable('questionList', questionListCopy))
        } catch (error) {
            console.log(error)
        }
    }
}

export function updateQuestion(question) {
    return async function(dispatch, getState) {
        const team = getState().auth.team
        try {
            question.createdAt && delete question.createdAt
            question.updatedAt && delete question.updatedAt
            await questionHelper.updateQuestion(question)
            dispatch(loadTeamQuestions(team, true))
        } catch (error) {
            console.log(error)
        }
    }
}

export function createQuestion(question, bank) {
    return async function(dispatch, getState) {
        const team = getState().auth.team
        try {
            await questionHelper.createQuestion(question, bank)
            dispatch(loadTeamQuestions(team, true))
            dispatch(loadQuestionsForBank(bank))
        } catch (error) {
            console.log(error)
        }
    }
}

export function createFakeAnswers() {
    return async function(dispatch, getState) {
        const team = getState().auth.team
        const questionList = getState().users.questionList

        try {
            let result = await fakeDataHelper.createRandomAnswersForUser(questionList)
        } catch (error) {
            console.log(error)
        }
    }
}
