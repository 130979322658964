import * as helper from '../../helpers/helpers'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { runQuery, runMutation } from './gqlHelper'

export async function getConnectorsById(connectorIds) {
  let promises = []
  for (let connectorId of connectorIds) {
    promises.push(getConnectorById(connectorId))
  }
  let connectors = await Promise.all(promises)
  connectors.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    } else {
      return -1
    }
  })
  return connectors;
}

export async function getConnectorById(connectorId) {
  let body = {
    id: connectorId
  }
  const resultObj = await runQuery(queries.getConnector, body);
  return resultObj.data.getConnector;
}


export async function createSalesforceConnector(name, code, companyId) {
  const input = {
    input: {
      type: 'salesforce',
      name: name,
      code: code,
      status: 'not_connected',
      timestamp: helper.getNowTimestamp(),
      companyId: companyId,
      connectorCompanyId: companyId
    }
  }
  const resultObj = await runMutation(mutations.createConnector, input)
  return resultObj.data.createConnector
}

export async function getConnectorsByCode(code) {
  const searchInput = {
    code: code
  }
  const resultObj = await runQuery(queries.connectorByCode, searchInput)
  return resultObj.data.connectorByCode.items
}