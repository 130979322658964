import React, { Component } from 'react'
import { connect } from 'react-redux'
// import * as helper from '../../helpers/helpers'
import logoBlack from '../../assets/logo_black.svg'
import {
  signUp,
  setAuthVariable,
  confirmCode,
  resetPassword,
  resetPasswordSubmit
} from '../../redux/actions/authActions'

function mapStateToProps(state) {
  return {
    resetPasswordError: state.auth.resetPasswordError,
    resetPasswordEmail: state.auth.resetPasswordEmail,
    resetPasswordCode: state.auth.resetPasswordCode,
    resetPasswordNewPassword: state.auth.resetPasswordNewPassword,
    resetPasswordConfirmNewPassword: state.auth.resetPasswordConfirmNewPassword,

    confirmationCode: state.auth.confirmationCode,
    confirmationCodeError: state.auth.confirmationCodeError,
    confirming: state.auth.confirming
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
    signUp: () => {
      dispatch(signUp())
    },
    confirmCode: () => {
      dispatch(confirmCode())
    },
    resetPassword: () => {
      dispatch(resetPassword())
    },
    resetPasswordSubmit: () => {
      dispatch(resetPasswordSubmit())
    }
  }
}

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      byPassEmail: false
    }
  }

  onSubmit = event => {
    this.props.resetPassword()
    event.preventDefault()
    this.setState({
      submitted: true
    })
  }

  onCodeSubmit = event => {
    this.props.resetPasswordSubmit()
    event.preventDefault()
  }

  onEmailChange = event => {
    this.props.setAuthVariable('resetPasswordError', '')
    this.props.setAuthVariable('resetPasswordEmail', event.target.value)
  }

  onCodeChange = event => {
    this.props.setAuthVariable('resetPasswordError', '')
    this.props.setAuthVariable('resetPasswordCode', event.target.value)
  }

  onNewPasswordChange = event => {
    this.props.setAuthVariable('resetPasswordError', '')
    this.props.setAuthVariable('resetPasswordNewPassword', event.target.value)
  }

  onConfirmNewPasswordChange = event => {
    this.props.setAuthVariable('resetPasswordError', '')
    this.props.setAuthVariable('resetPasswordConfirmNewPassword', event.target.value)
  }

  onBackClick = () => {
    this.props.setAuthVariable('page', 'sign_up')
  }

  backToSignIn = () => {
    this.props.setAuthVariable('page', 'sign_in')
  }

  onHaveCode = () => {
    this.setState({
      submitted: true,
      byPassEmail: true
    })
  }

  render() {
    const {
      resetPasswordError,
      resetPasswordEmail,
      resetPasswordCode,
      resetPasswordNewPassword,
      resetPasswordConfirmNewPassword
    } = this.props
    const { submitted, byPassEmail } = this.state
    // let emailValid = helper.validateEmail(signUpEmail);
    let disabled = true
    if (resetPasswordEmail) {
      disabled = false
    }

    let disabledCode = true
    if (
      resetPasswordEmail &&
      resetPasswordCode &&
      resetPasswordNewPassword &&
      resetPasswordNewPassword === resetPasswordConfirmNewPassword
    ) {
      disabledCode = false
    }
    return (
      <div className='center gray_background'>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className={'card margin_auto sign_in_card'}>
          <br></br>
          <img src={logoBlack} width='220' alt='logo' />
          <br></br>
          <br></br>
          {!submitted ? (
            <div>Reset password</div>
          ):(
            <div>Check email for verification code</div>
          )}
          <br></br>
          <br></br>

          {!submitted ? (
            <form onSubmit={this.onSubmit}>
              <input
                value={resetPasswordEmail}
                type='text'
                placeholder='Email'
                name='email'
                onChange={this.onEmailChange}
              />
              <br></br>
              <br></br>
              <button
                type='submit'
                className={'green_button'}
                disabled={disabled}
              >
                submit
              </button>
            </form>
          ) : (
            <form onSubmit={this.onCodeSubmit}>

              {byPassEmail ? (
                <span>
                  <input
                value={resetPasswordEmail}
                type='text'
                placeholder='Email'
                name='email'
                onChange={this.onEmailChange}
              />
                <br></br>
                </span>
              ):null}
              
              <input
              className={`${byPassEmail ? 'margin_top_5': ''}`}
                value={resetPasswordCode}
                type='text'
                placeholder='Verification code'
                name='code'
                onChange={this.onCodeChange}
              />
              <br></br>
              <input
                className={'margin_top_5'}
                value={resetPasswordNewPassword}
                type="password"
                placeholder='New password'
                name='password'
                onChange={this.onNewPasswordChange}
              />
              <br></br>
              <input
                className={'margin_top_5'}
                value={resetPasswordConfirmNewPassword}
                type="password"
                placeholder='Confirm new password'
                name='password'
                onChange={this.onConfirmNewPasswordChange}
              />
              <br></br>
              <br></br>
              <button
                type='submit'
                className={'green_button'}
                disabled={disabledCode}
              >
                submit
              </button>
            </form>
          )}

          <br></br>
          <div className={'red_font'}>{resetPasswordError}</div>
          <br></br>
          <div className={'link'} onClick={this.backToSignIn}>Back to sign in</div>
          <br></br>
          {!submitted ?(
            <div className={'link'} onClick={this.onHaveCode}>I already have a code</div>
          ):null}
          <br></br>
          <br></br>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword)
