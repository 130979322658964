// import * as helper from '../../helpers/helpers'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import {runQuery, runMutation} from './gqlHelper'

export async function createQuestions(questions, bank) {
  let promises = []
  for (let question of questions) {
    promises.push(createQuestion(question, bank))
  }
  let result = await Promise.all(promises)
  return result
}

export async function createQuestion(question, bank) {
  let input = {
    input: {
      text: question.text,
      category: 'Custom', // TODO change this
      bank: bank.name, // TODO change this
      questionBankId: bank.id,
      options: question.options
    }
  }
  const resultObj = await runMutation(mutations.createQuestion, input)
  return resultObj.data.createQuestion
}

export async function getQuestionsByBankId(bankId) {
  let body = {
    questionBankId: bankId
  }
  let questions = []
  let more = true
  while (more) {
    more = false
    const resultObj = await runQuery(queries.questionsByQuestionBankId, body)
    const result = resultObj.data.questionsByQuestionBankId
    questions = questions.concat(result.items)
    if (result.nextToken) {
      more = true
      body.nextToken = result.nextToken
    }
  }
  // console.log(questions)
  return questions
}

export async function updateQuestion(question) {
  delete question.updated
  let input = {
    input: question
  }
  const resultObj = await runMutation(mutations.updateQuestion, input)
  return resultObj.data.updateQuestion
}
