import React, {Component} from 'react'
import {connect} from 'react-redux'
import {setAuthVariable} from '../../redux/actions/authActions'
import lady_dashboard_man_computer from '../../assets/lady_dashboard_man_computer.svg';
import man_plans from '../../assets/man_plans.svg';

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    }
  }
}

class SignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  signInClick = () => {
    this.props.setAuthVariable('page', 'sign_in')
  }

  joinClick = () => {
    this.props.setAuthVariable('page', 'join')
    this.props.setAuthVariable('path', 'join')
  }

  startClick = () => {
    this.props.setAuthVariable('page', 'start')
    this.props.setAuthVariable('path', 'start')
  }

  render() {
    return (
      <div className={'sign_up_container'}>
        <div className={'sign_up_left center'}>
          <div>
            <h2>My company already has an account</h2>
            <br></br>
            <div className={'margin_auto'}>
              <img src={lady_dashboard_man_computer} height="200" alt="logo" />
            </div>
            <br></br>
            <br></br>
            <button className={'green_button'} onClick={this.joinClick}>join</button>
            <br></br>
            <br></br>

            <div>
              Already signed up?{' '}
              <span onClick={this.signInClick} className={'link'}>
                Sign in
              </span>
            </div>
          </div>
        </div>
        <div className={'sign_up_right center'}>
          <div>
            <h2>I want to start a company</h2>
            <br></br>
            <br></br>
            <div className={'margin_auto'}>
              <img src={man_plans} height="200" alt="logo" />
            </div>
            <br></br>
            <button className={'yellow_button'} onClick={this.startClick}>start</button>
            <br></br>
            <br></br>

            <br></br>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)
