import _ from 'lodash'
import React, { Component } from 'react'
import * as helper from '../../helpers/helpers'

export default class AveragesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowOpen: {},
      openAnswers: []
    }
  }

  onShowDetails = row => {
    const { showDetails, answers } = this.props
    const { rowOpen } = this.state
    if (showDetails) {
      if (rowOpen.id === row.id) {
        this.setState({
          rowOpen: {},
          openAnswers: []
        })
      } else {
        const openAnswers = []
        for (let answer of answers) {
          if (answer.teamQuestionId === row.id) {
            openAnswers.push(answer)
          }
        }
        this.setState({
          rowOpen: row,
          openAnswers
        })
      }
    }
  }

  render() {
    const { rows, sizeClass, title, showDetails } = this.props
    const { rowOpen, openAnswers } = this.state
    // console.log(openAnswers);
    return (
      <div className={sizeClass} style={{ padding: '5px' }}>
        <div className={'card skinny left'}>
          <h2 className={'thin'}>{title}</h2>
          <div className={'spacer_5'}></div>
          <div className={'smaller_text margin_top_5'}>Past 90 days</div>
          <br></br>
          {_.map(rows, row => {
            return (
              <span key={row.id}>
                <div
                  className={`average_row ${
                    row.id === rowOpen.id ? 'row_selected' : null
                  } ${showDetails ? 'pointer' : null}`}
                  onClick={() => this.onShowDetails(row)}
                >
                  <div className={'average_text'}>{row.text}</div>
                  <div className={`average_number ${row.class}`}>{row.average}</div>
                </div>
                {row.id === rowOpen.id ? (
                  <div className={'answer_detail'}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr className={'table_header_row'}>
                          {/* <th className={'table_header_item'}>Email</th> */}
                          <th className={'table_header_item'}>Date</th>
                          <th className={'table_header_item'}>Answer</th>
                          <th className={'table_header_item'}>Weight</th>
                        </tr>
                      </thead>

                      {_.map(openAnswers, answer => {
                        return (
                          <tbody key={answer.id} className={'table_row'}>
                            <tr key={answer.id}>
                              {/* <td className={'table_item'}>{answer.email}</td> */}
                              <td className={'table_item'}>{helper.formatDate(answer.timestamp)}</td>
                              <td className={'table_item'}>{answer.answer}</td>
                              <td className={'table_item'}>{answer.weight}/100</td>
                            </tr>
                            {answer.extraText ? (
                              <tr>
                                <td className={'table_item'} colspan='3'>Feeback:</td>
                              </tr>
                            ) : null}
                            {answer.extraText ? (
                              <tr className={'table_row'}>
                                <td className={'table_item'} colspan='3'>{answer.extraText}</td>
                              </tr>
                            ) : null}
                          </tbody>
                        )
                      })}
                    </table>
                  </div>
                ) : null}
              </span>
            )
          })}
          <br></br>
        </div>
      </div>
    )
  }
}
