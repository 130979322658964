// import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import GroupMembersList from './GroupMembersList'

function mapStateToProps(state) {
  return {
    team: state.auth.team
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

class GroupMembers extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <GroupMembersList />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupMembers)
