import React, {Component} from 'react'
import _ from 'lodash'
import * as helper from '../../helpers/helpers'
import {connect} from 'react-redux'
import Select from 'react-dropdown-select'
import {MdClose, MdAdd, MdCheck, MdRemove, MdEdit} from 'react-icons/md'
import {IoIosArrowUp, IoIosArrowDown, IoIosWarning} from 'react-icons/io'
import {setAuthVariable} from '../../redux/actions/authActions'
import {
  setUsersVariable,
  updateOrCreateTeam
} from '../../redux/actions/usersActions'

function mapStateToProps(state) {
  return {
    team: state.auth.team,
    teamList: state.users.teamList,
    teamMembers: state.users.teamMembers,
    newTeamMembersMap: state.users.newTeamMembersMap
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
    setUsersVariable: (fieldname, value) => {
      dispatch(setUsersVariable(fieldname, value))
    },
    updateOrCreateTeam: () => {
      dispatch(updateOrCreateTeam())
    }
  }
}

function formatSearchData(teamMembers) {
  let keys = []
  for (let item of teamMembers) {
    keys = _.union(keys, Object.keys(item))
  }
  _.remove(keys, function (n) {
    return (
      n === 'id' ||
      n === 'teamId' ||
      // n === 'team' ||
      n === 'name' ||
      n === 'email' ||
      n === 'connectorId' ||
      n === 'connector'
    )
  })
  // we remove these and add them back in so they will be on the bottom
  keys.push('name')
  keys.push('email')
  let map = {}
  for (let key of keys) {
    map[key] = []
  }
  for (let item of teamMembers) {
    for (let key of keys) {
      if (item[key]) {
        let index = _.findIndex(map[key], function (m) {
          return m.value === item[key]
        })
        if (index === -1) {
          map[key].push({
            value: item[key],
            label: item[key],
            key: key
          })
        }
      }
    }
  }
  return map
}
function filterTeamMembers(teamMembers, searchValues) {
  let tempTeam = _.cloneDeep(teamMembers)
  _.remove(tempTeam, function (n) {
    for (let row of searchValues) {
      if (row.value !== n[row.key]) {
        return true
      }
    }
    return false
  })
  return tempTeam
}

function isActive(item, state) {
  for (let row of state) {
    if (row.value === item.value) {
      return true
    }
  }
  return false
}

function formatMembersList(team, teamMembers) {
  let list = []
  for (let member of teamMembers) {
    if (member.teamId === team.id) {
      list.push(member)
    }
  }
  return list
}

class GroupMembersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editName: false,
      hasChanged: false,
      searchValues: [],
      column: 'name',
      reverse: false,
      membersList: formatMembersList(this.props.team, this.props.teamMembers)
    }
  }

  onSelect = values => {
    this.setState({
      searchValues: values
    })
  }

  onFilterBy = column => {
    let reverse = false
    if (this.state.column === column) {
      reverse = !this.state.reverse
    }
    this.setState({
      column,
      reverse
    })
  }

  onSelectAll = members => {
    const {newTeamMembersMap, team} = this.props
    let newMap = _.cloneDeep(newTeamMembersMap)
    let oldMembers = _.cloneDeep(newMap[team.id]) || []
    for (let newMember of members) {
      let index = _.findIndex(oldMembers, function (m) {
        return m.id === newMember.id
      })
      if (index === -1) {
        oldMembers.push(newMember)
      }
    }
    newMap[team.id] = oldMembers
    this.props.setUsersVariable('newTeamMembersMap', newMap)
    this.setState({
      hasChanged: true
    })
  }

  onAddMember = member => {
    const {newTeamMembersMap, team} = this.props
    let newMap = _.cloneDeep(newTeamMembersMap)
    let members = _.cloneDeep(newMap[team.id]) || []
    let index = _.findIndex(members, function (m) {
      return m.id === member.id
    })
    if (index === -1) {
      members.push(member)
    }
    newMap[team.id] = members
    this.props.setUsersVariable('newTeamMembersMap', newMap)
    this.setState({
      hasChanged: true
    })
  }

  onRemoveAll = () => {
    const {newTeamMembersMap, team} = this.props
    let newMap = _.cloneDeep(newTeamMembersMap)
    console.log(newMap)
    newMap[team.id] = []
    this.props.setUsersVariable('newTeamMembersMap', newMap)
    this.setState({
      hasChanged: true
    })
  }

  onRemoveMember = member => {
    const {newTeamMembersMap, team} = this.props
    let newMap = _.cloneDeep(newTeamMembersMap)
    let members = _.cloneDeep(newMap[team.id]) || []
    _.remove(members, function (m) {
      return m.id === member.id
    })
    newMap[team.id] = members
    console.log(newMap)
    this.props.setUsersVariable('newTeamMembersMap', newMap)
    this.setState({
      hasChanged: true
    })
  }

  onNameChange = event => {
    const {team} = this.props
    let teamClone = _.cloneDeep(team)
    teamClone.newName = event.target.value
    this.props.setAuthVariable('team', teamClone)
    this.setState({
      hasChanged: true
    })
  }

  onSaveTeam = () => {
    this.props.updateOrCreateTeam()
    this.setState({
      hasChanged: false,
      editName: false
    })
  }

  onEditClick = () => {
    this.setState({
      editName: true,
      hasChanged: true
    })
  }

  render() {
    const {team, teamMembers, teamList, newTeamMembersMap} = this.props
    const {searchValues, column, reverse, editName, hasChanged} = this.state
    const members = newTeamMembersMap[team.id] || []
    const name = team.newName || team.name || ''
    const isSaveDisabled = !name ? true : false

    // console.log(teamMembers);

    for (let m of teamMembers) {
      m.team = helper.findTeamName(m.teamId, teamList)
    }
    let searchData = formatSearchData(teamMembers)
    let searchKeys = Object.keys(searchData)
    let filteredTeamMembers = filterTeamMembers(teamMembers, searchValues)
    filteredTeamMembers = filteredTeamMembers.sort((a, b) => {
      let yep = 1
      let nope = -1
      if (reverse) {
        yep = -1
        nope = 1
      }
      if (!b[column]) {
        return nope
      } else if (!a[column]) {
        return yep
      } else if (a[column].toLowerCase() > b[column].toLowerCase()) {
        return yep
      }
      return nope
    })

    return (
      <div>
        {/* <br></br> */}
        {/* <div>
          {team.name} {team.id}
        </div>
        <div>
          {members.length}/{teamMembers.length}
        </div> */}

        {/* save button */}
        {/* {hasChanged ? (
          <button
            className={'green_button'}
            disabled={isSaveDisabled}
            onClick={this.onSaveTeam}
          >
            save changes
          </button>
        ):null} */}
        <br></br>
        <br></br>
        <div className={'card'}>
          <div className={'flex_space_between align_center'}>
            <div>
              {editName ? (
                <input
                  value={name}
                  placeholder='Team name'
                  name='team_name'
                  onChange={this.onNameChange}
                />
              ) : (
                  <div className={'flex_wrap_row pointer align_center'}>
                    <h2>{name}&nbsp;&nbsp;</h2>
                    <MdEdit style={{fontSize: '20px'}} onClick={this.onEditClick} />
                  </div>
                )}
            </div>
            {hasChanged ? (
              <button
                className={'green_button'}
                disabled={isSaveDisabled}
                onClick={this.onSaveTeam}
              >
                save changes
              </button>
            ) : null}
          </div>


          {team.isDefault ? <div>This is the default group.</div> : null}


          <br></br>
          <br></br>
          <h3>Current Group Members</h3>
          <div className={'spacer_5'}></div>
          {/* proposed members / current? */}
          <table style={{width: '100%'}}>
            <thead>
              <tr className={'table_header_row'}>
                <th
                  className={'capitalize table_header_item'}
                  onClick={() => this.onRemoveAll()}
                >
                  remove all
                </th>
                <th className={'table_header_item'}></th>

                {_.map(searchKeys, key => {
                  return (
                    <th
                      key={key}
                      onClick={() => this.onFilterBy(key)}
                      className={'capitalize table_header_item'}
                    >
                      {key}&nbsp;
                      {key === column && reverse && (
                        <IoIosArrowUp
                          style={{fontSize: '16px', marginBottom: '-2px'}}
                        />
                      )}
                      {key === column && !reverse && (
                        <IoIosArrowDown
                          style={{fontSize: '16px', marginBottom: '-2px'}}
                        />
                      )}
                    </th>
                  )
                })}
                {/* <th>
                  Team
                </th> */}
              </tr>
            </thead>
            <tbody>
              {_.map(members, member => {
                return (
                  <tr key={member.id} className={'table_row'}>
                    <td
                      onClick={() => this.onRemoveMember(member)}
                      className={'table_item center pointer'}
                    >
                      <MdRemove style={{fontSize: '20px'}} />
                    </td>
                    {member.teamId !== team.id ? <td className={'table_item'}>
                      <IoIosWarning style={{fontSize: '20px', color: 'red'}} />
                    </td> : <td className={'table_item'}></td>}
                    {_.map(searchKeys, key => {
                      return <td key={member[key]} className={'table_item'}>{member[key]}</td>
                    })}
                    {/* <td>
                      {member.team}
                    </td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>


          {/* </div> */}
          <br></br>
          <br></br>
          <br></br>
          {/* <div className={'card'}> */}


          {/* all team members, filter, filtered team members */}
          {teamMembers.length === 0 ? (
            <div>
              No team members have been uploaded yet. You can still add people
              manually
            </div>
          ) : (
              <div>
                <h3>Potential Group Members</h3>
                <div className={'spacer_5'}></div>
                <Select
                  multi
                  style={{width: '100%', maxWidth: '600px'}}
                  onChange={values => this.onSelect(values)}
                  contentRenderer={({props, state, methods}) => {
                    return (
                      <div
                        className={'flex_wrap_row'}
                        style={{paddingLeft: 5, paddingRight: 5}}
                      >
                        {state.values.length === 0 ? <div>Filter...</div> : null}

                        {_.map(state.values, item => {
                          return (
                            <div
                              key={item.value}
                              onClick={() => methods.addItem(item)}
                              style={{padding: '1px', paddingRight: '5px'}}
                            >
                              <span className={'flex_wrap_row dropdown_tag'}>
                                {item.label}&nbsp;
                              <MdClose style={{fontSize: '20px', marginTop: '-2px'}} />
                              </span>
                            </div>
                          )
                        })}
                      </div>
                    )
                  }}
                  dropdownRenderer={({props, state, methods}) => {
                    let keys = Object.keys(searchData)
                    // console.log(state);
                    return (
                      <div className={'dropdown_container'}>
                        {_.map(keys, key => {
                          return (
                            <div key={key} className={`dropdown_item`}>
                              <div className={'dropdown_header'}>{key}</div>
                              {_.map(searchData[key], item => {
                                let active = isActive(item, state.values)
                                return (
                                  <div
                                    key={item.value}
                                    onClick={() => methods.addItem(item)}
                                    className={`dropdown_item ${
                                      active ? 'dropdown_item_active' : ''
                                      }`}
                                  >
                                    {item.label}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>
                    )
                  }}
                />
              </div>
            )}
          <br></br>

          <table style={{width: '100%'}}>
            <thead>
              <tr className={'table_header_row'}>
                <th
                  className={'capitalize table_header_item'}
                  onClick={() => this.onSelectAll(filteredTeamMembers)}
                >
                  {/* select all <input type="checkbox" value="Bike" /> */}
                  add all
                </th>

                {_.map(searchKeys, key => {
                  return (
                    <th
                      key={key}
                      onClick={() => this.onFilterBy(key)}
                      className={'capitalize table_header_item pointer'}
                    >
                      {key}&nbsp;
                      {key === column && reverse && (
                        <IoIosArrowUp
                          style={{fontSize: '20px', marginBottom: '-2px'}}
                        />
                      )}
                      {key === column && !reverse && (
                        <IoIosArrowDown
                          style={{fontSize: '20px', marginBottom: '-2px'}}
                        />
                      )}
                    </th>
                  )
                })}
                {/* <th
                  onClick={() => this.onFilterBy('team')}
                >
                  Team
                </th> */}
              </tr>
            </thead>
            <tbody>
              {_.map(filteredTeamMembers, member => {
                let index = _.findIndex(members, function (m) {
                  return m.id === member.id
                })
                return (
                  <tr key={member.id} className={'table_row'}>
                    <td className={'table_item center'}>
                      {index === -1 ? <span onClick={() => this.onAddMember(member)} className={'pointer'}>
                        <MdAdd style={{fontSize: '20px'}} />
                      </span> : <span>
                          <MdCheck style={{fontSize: '20px'}} />
                        </span>}
                    </td>
                    {_.map(searchKeys, key => {
                      return (
                        <td key={member[key] + key + member.id} className={'table_item'}>{member[key]}</td>
                      )
                    })}
                    {/* <td>
                      {member.team}
                    </td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <br></br>
        <br></br>
        <br></br>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupMembersList)
