// import * as helper from '../../helpers/helpers'
import * as questionHelper from './questionHelper'
import * as teamQuestionHelper from './teamQuestionHelper'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { runQuery, runMutation } from './gqlHelper'

export async function createNewBank(bank, team, company) {
  let body = {
    name: bank.name,
    public: false,
    teamId: team.id
  }
  // if(share) { // change this
  //   body.companyId = company.id
  // }
  if (bank.description) {
    body.description = bank.description
  }
  const input = {
    input: body
  }
  const result = await runMutation(mutations.createQuestionBank, input)
  return result.data.createQuestionBank
}

export async function updateBank(bank) {
  // id: ID!
  // name: String
  // description: String
  // public: Boolean
  // companyId: ID
  // teamId: ID
  const temp = {
    id: bank.id,
    name: bank.name,
    public: false
  }
  if (bank.description) {
    temp.description = bank.description
  }
  if (bank.companyId) {
    temp.companyId = bank.companyId
  }
  if (bank.teamId) {
    temp.teamId = bank.teamId
  }
  const input = {
    input: temp
  }
  const result = await runMutation(mutations.updateQuestionBank, input)
  return result.data.updateQuestionBank
}

export async function createNewQuestionsForBank(questions, bank, team) {
  let newQuestions = await questionHelper.createQuestions(questions, bank)
  await teamQuestionHelper.createTeamQuestions(newQuestions, team)
}

export async function getPublicTeamCompanyBanks(teamId, companyId) {
  let body = {
    filter: {
      or: [
        {
          public: {
            eq: true
          }
        },
        {
          teamId: {
            eq: teamId
          }
        },
        {
          companyId: {
            eq: companyId
          }
        }
      ]
    }
  }
  let bankList = []
  let more = true
  while (more) {
    more = false
    const resultObj = await runQuery(queries.listQuestionBanks, body)
    const result = resultObj.data.listQuestionBanks
    bankList = bankList.concat(result.items)
    if (result.nextToken) {
      more = true
      body.nextToken = result.nextToken
    }
  }
  return bankList;
}