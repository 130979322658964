import * as helper from '../../helpers/helpers'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import {runQuery, runMutation} from './gqlHelper'

export async function getTeamQuestionsByTeamId(teamId) {
  let body = {
    teamID: teamId
  }
  let questions = []
  let more = true
  while (more) {
    more = false
    const resultObj = await runQuery(queries.teamQuestionsByTeamId, body)
    const result = resultObj.data.teamQuestionsByTeamId
    questions = questions.concat(result.items)
    if (result.nextToken) {
      more = true
      body.nextToken = result.nextToken
    }
  }
  // console.log(questions)
  return questions
}

export async function createTeamQuestions(questions, team) {
  let promises = []
  for (let question of questions) {
    promises.push(createTeamQuestion(question, team))
  }
  let result = await Promise.all(promises)
  return result
}

export async function createTeamQuestion(question, team) {
  let input = {
    input: {
      active: question.active ? true : false,
      pastAskDates: [],
      nextAskDate: 0,
      teamQuestionQuestionId: question.id,
      teamID: team.id
    }
  }
  const resultObj = await runMutation(mutations.createTeamQuestion, input)
  return resultObj.data.createTeamQuestion
}

export async function updateTeamQuestions(teamQuestions) {
  let promises = []
  for (let question of teamQuestions) {
    promises.push(updateTeamQuestion(question))
  }
  let result = await Promise.all(promises)
  return result
}

export async function updateTeamQuestion(question) {
  let input = {
    input: {
      id: question.id,
      active: question.active,
      nextAskDate: question.nextAskDate,
      pastAskDates: question.pastAskDates,
      teamID: question.teamID,
      teamQuestionQuestionId: question.question.id
    }
  }
  let resultObj = await runMutation(mutations.updateTeamQuestion, input)
  return resultObj.data.updateTeamQuestion
}

export async function scheduleQuestionsStartingToday(questions, team) {
  const daySeconds = 86400
  const todayMidnight = helper.getTodayMidnightUTC()
  let next = todayMidnight - daySeconds
  for (let question of questions) {
    let weekend = true
    while (weekend) {
      weekend = false
      next += daySeconds
      let d = new Date(next * 1000)
      let day = d.getUTCDay()
      if (day === 0 || day === 6) {
        weekend = true
      }
    }
    question.nextAskDate = next
    question.pastAskDates.push(next)
  }
  // await updateTeamQuestions(questions)
  return questions;
}
