// import _ from 'lodash'
import React from 'react'

export default function Average(props) {
  const { score, title } = props
  let sizeClass = ''
  try {
    let scoreInt = parseFloat(score)
    if (scoreInt >= 70.0) {
      sizeClass = 'good_average'
    } else if (scoreInt <= 40.0) {
      sizeClass = 'bad_average'
    } else {
      sizeClass = 'neutral_average'
    }
  } catch (error) {
    // console.log(error);
  }

  return (
    <div className={'single_average_card_container'}>
        <div className={`single_average_card`}>
          <div className={'capitalize single_average_card_title'}>{title}</div>
          <div className={'single_average_card_score'}>{score}</div>
        </div>
        <div className={`single_average_card_line ${sizeClass}`}></div>
    </div>
  )
}
