import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dashboard from './Dashboard'
import Queue from './Queue'
// import Users from './Users'
import NoConnectors from './NoConnectors'
// import FreeResponse from './FreeResponse'
import Marketplace from './Marketplace'
import Schedule from './Schedule'
import ConnectorAccess from './ConnectorAccess';
import GroupMembers from './GroupMembers'
// import { FiSettings } from "react-icons/fi";
import { MdSettings } from "react-icons/md";
import { setAuthVariable } from '../../redux/actions/authActions'

function mapStateToProps(state) {
  return {
    view: state.auth.view,
    connectorList: state.users.connectorList,
    connectorsLoaded: state.auth.connectorsLoaded,

    connector: state.auth.connector,
    team: state.auth.team
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    }
  }
}

class DashboardTabContent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getCurrentView = (view, connectorList, connectorsLoaded) => {
    if(!connectorsLoaded) {
      // return <h2>Loading..</h2>
      return null;
    }else if (connectorList.length === 0) {
      return <NoConnectors />
    } else if (view === 'queue') {
      return <Queue />
    // } else if (view === 'users') {
    //   return <Users />
    } else if (view === 'schedule') {
      return <Schedule />
    } else if (view === 'marketplace') {
      return <Marketplace />
    } else if (view === 'access') {
      return <ConnectorAccess />
    } else if (view === 'members') {
      return <GroupMembers />
    }
    return <Dashboard />
  }

  onViewChange = view => {
    this.props.setAuthVariable('view', view)
  }

  viewAccountPageClick = () => {
    this.props.setAuthVariable('viewAccountPage', true)
  } 

  render() {
    const {
      view,
      connectorList,
      connectorsLoaded,

      // connector,
      // team
    } = this.props

    return (
      <div>
        {/* <div>
          <div className={'flex_space_between'} style={{ width: '600px'}}>
            <span>connector:</span>
            <span>{connector.name}</span>
            <span>{connector.id}</span>
            </div>
            <br></br>
            <div className={'flex_space_between'} style={{ width: '600px'}}><span>team:</span>
            <span>{team.name}</span>
            <span>{team.id}</span></div>
        </div> */}
        <div className={'right'}>
          {/* <FiSettings style={{ fontSize: '24px'}}/> */}
          <MdSettings style={{ fontSize: '24px'}} onClick={this.viewAccountPageClick} className={'pointer'}/>
        </div>
        <div className={'tab_row'}>
          <div
            className={`tab ${view === 'dashboard' ? 'active_tab' : ''}`}
            onClick={() => this.onViewChange('dashboard')}
          >
            Dashboard
          </div>
          <div
            className={`tab ${view === 'schedule' ? 'active_tab' : ''}`}
            onClick={() => this.onViewChange('schedule')}
          >
            Schedule
          </div>
          <div
            className={`tab ${view === 'marketplace' ? 'active_tab' : ''}`}
            onClick={() => this.onViewChange('marketplace')}
          >
            Marketplace
          </div>
          <div
            className={`tab ${view === 'members' ? 'active_tab' : ''}`}
            onClick={() => this.onViewChange('members')}
          >
            Members
          </div>
          <div
            className={`tab ${view === 'access' ? 'active_tab' : ''}`}
            onClick={() => this.onViewChange('access')}
          >
            Share
          </div>

          {/* <div
            className={`tab ${view === 'users' ? 'active_tab' : ''}`}
            onClick={() => this.onViewChange('users')}
          >
            Users
          </div> */}
          {/* <div className={`tab ${view === 'queue' ? 'active_tab': ''}`} onClick={() => this.onViewChange('queue')}>Queue</div> */}
        </div>
        {/* {team.name ? (
            <div>
              {team.name} > {view}
            </div>
          ) : null} */}
        <div>{this.getCurrentView(view, connectorList, connectorsLoaded)}</div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardTabContent)
