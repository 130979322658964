/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      start
      invites {
        items {
          id
          email
          group
          accepted
          revoked
          timestamp
          companyId
          connectors
          createdAt
          updatedAt
        }
        nextToken
      }
      connectors {
        items {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      start
      invites {
        items {
          id
          email
          group
          accepted
          revoked
          timestamp
          companyId
          connectors
          createdAt
          updatedAt
        }
        nextToken
      }
      connectors {
        items {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      start
      invites {
        items {
          id
          email
          group
          accepted
          revoked
          timestamp
          companyId
          connectors
          createdAt
          updatedAt
        }
        nextToken
      }
      connectors {
        items {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInvite = /* GraphQL */ `
  mutation CreateInvite(
    $input: CreateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    createInvite(input: $input, condition: $condition) {
      id
      email
      group
      accepted
      revoked
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      connectors
      createdAt
      updatedAt
    }
  }
`;
export const updateInvite = /* GraphQL */ `
  mutation UpdateInvite(
    $input: UpdateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    updateInvite(input: $input, condition: $condition) {
      id
      email
      group
      accepted
      revoked
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      connectors
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvite = /* GraphQL */ `
  mutation DeleteInvite(
    $input: DeleteInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    deleteInvite(input: $input, condition: $condition) {
      id
      email
      group
      accepted
      revoked
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      connectors
      createdAt
      updatedAt
    }
  }
`;
export const createConnector = /* GraphQL */ `
  mutation CreateConnector(
    $input: CreateConnectorInput!
    $condition: ModelConnectorConditionInput
  ) {
    createConnector(input: $input, condition: $condition) {
      id
      type
      code
      name
      status
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConnector = /* GraphQL */ `
  mutation UpdateConnector(
    $input: UpdateConnectorInput!
    $condition: ModelConnectorConditionInput
  ) {
    updateConnector(input: $input, condition: $condition) {
      id
      type
      code
      name
      status
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConnector = /* GraphQL */ `
  mutation DeleteConnector(
    $input: DeleteConnectorInput!
    $condition: ModelConnectorConditionInput
  ) {
    deleteConnector(input: $input, condition: $condition) {
      id
      type
      code
      name
      status
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember(
    $input: CreateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    createTeamMember(input: $input, condition: $condition) {
      id
      name
      email
      profile
      role
      manager
      teamId
      connectorId
      connector {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $input: UpdateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      name
      email
      profile
      role
      manager
      teamId
      connectorId
      connector {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember(
    $input: DeleteTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    deleteTeamMember(input: $input, condition: $condition) {
      id
      name
      email
      profile
      role
      manager
      teamId
      connectorId
      connector {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConnectorCode = /* GraphQL */ `
  mutation CreateConnectorCode(
    $input: CreateConnectorCodeInput!
    $condition: ModelConnectorCodeConditionInput
  ) {
    createConnectorCode(input: $input, condition: $condition) {
      id
      code
      createdAt
      updatedAt
    }
  }
`;
export const updateConnectorCode = /* GraphQL */ `
  mutation UpdateConnectorCode(
    $input: UpdateConnectorCodeInput!
    $condition: ModelConnectorCodeConditionInput
  ) {
    updateConnectorCode(input: $input, condition: $condition) {
      id
      code
      createdAt
      updatedAt
    }
  }
`;
export const deleteConnectorCode = /* GraphQL */ `
  mutation DeleteConnectorCode(
    $input: DeleteConnectorCodeInput!
    $condition: ModelConnectorCodeConditionInput
  ) {
    deleteConnectorCode(input: $input, condition: $condition) {
      id
      code
      createdAt
      updatedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      deletable
      isDefault
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      connectorId
      connector {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      members
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      deletable
      isDefault
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      connectorId
      connector {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      members
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      deletable
      isDefault
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      connectorId
      connector {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      members
      createdAt
      updatedAt
    }
  }
`;
export const createTeamQuestion = /* GraphQL */ `
  mutation CreateTeamQuestion(
    $input: CreateTeamQuestionInput!
    $condition: ModelTeamQuestionConditionInput
  ) {
    createTeamQuestion(input: $input, condition: $condition) {
      id
      active
      nextAskDate
      pastAskDates
      question {
        id
        text
        category
        bank
        questionBankId
        options
        createdAt
        updatedAt
      }
      teamID
      team {
        id
        name
        deletable
        isDefault
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        members
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamQuestion = /* GraphQL */ `
  mutation UpdateTeamQuestion(
    $input: UpdateTeamQuestionInput!
    $condition: ModelTeamQuestionConditionInput
  ) {
    updateTeamQuestion(input: $input, condition: $condition) {
      id
      active
      nextAskDate
      pastAskDates
      question {
        id
        text
        category
        bank
        questionBankId
        options
        createdAt
        updatedAt
      }
      teamID
      team {
        id
        name
        deletable
        isDefault
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        members
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamQuestion = /* GraphQL */ `
  mutation DeleteTeamQuestion(
    $input: DeleteTeamQuestionInput!
    $condition: ModelTeamQuestionConditionInput
  ) {
    deleteTeamQuestion(input: $input, condition: $condition) {
      id
      active
      nextAskDate
      pastAskDates
      question {
        id
        text
        category
        bank
        questionBankId
        options
        createdAt
        updatedAt
      }
      teamID
      team {
        id
        name
        deletable
        isDefault
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        members
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      text
      category
      bank
      questionBankId
      options
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      text
      category
      bank
      questionBankId
      options
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      text
      category
      bank
      questionBankId
      options
      createdAt
      updatedAt
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      email
      timestamp
      answer
      extraText
      weight
      teamQuestionId
      teamQuestion {
        id
        active
        nextAskDate
        pastAskDates
        question {
          id
          text
          category
          bank
          questionBankId
          options
          createdAt
          updatedAt
        }
        teamID
        team {
          id
          name
          deletable
          isDefault
          timestamp
          companyId
          connectorId
          members
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      email
      timestamp
      answer
      extraText
      weight
      teamQuestionId
      teamQuestion {
        id
        active
        nextAskDate
        pastAskDates
        question {
          id
          text
          category
          bank
          questionBankId
          options
          createdAt
          updatedAt
        }
        teamID
        team {
          id
          name
          deletable
          isDefault
          timestamp
          companyId
          connectorId
          members
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      email
      timestamp
      answer
      extraText
      weight
      teamQuestionId
      teamQuestion {
        id
        active
        nextAskDate
        pastAskDates
        question {
          id
          text
          category
          bank
          questionBankId
          options
          createdAt
          updatedAt
        }
        teamID
        team {
          id
          name
          deletable
          isDefault
          timestamp
          companyId
          connectorId
          members
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionBank = /* GraphQL */ `
  mutation CreateQuestionBank(
    $input: CreateQuestionBankInput!
    $condition: ModelQuestionBankConditionInput
  ) {
    createQuestionBank(input: $input, condition: $condition) {
      id
      name
      description
      public
      companyId
      teamId
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionBank = /* GraphQL */ `
  mutation UpdateQuestionBank(
    $input: UpdateQuestionBankInput!
    $condition: ModelQuestionBankConditionInput
  ) {
    updateQuestionBank(input: $input, condition: $condition) {
      id
      name
      description
      public
      companyId
      teamId
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionBank = /* GraphQL */ `
  mutation DeleteQuestionBank(
    $input: DeleteQuestionBankInput!
    $condition: ModelQuestionBankConditionInput
  ) {
    deleteQuestionBank(input: $input, condition: $condition) {
      id
      name
      description
      public
      companyId
      teamId
      createdAt
      updatedAt
    }
  }
`;
