import aws_exports from '../../aws-exports';
import Amplify, { API, graphqlOperation } from 'aws-amplify';

Amplify.configure({ ...aws_exports, Auth: { 
  clientMetadata: { 
    myCustomKey: 'myCustomValue'
  },
  mandatorySignIn: false,
}});

export function runQuery(query, body) {
  // return API.graphql(graphqlOperation(query, { filter: body }));
  return API.graphql(graphqlOperation(query, body));
}

export function runMutation(mutation, body) {
  return API.graphql(graphqlOperation(mutation, body));
}