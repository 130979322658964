import _ from 'lodash'
import React, { Component } from 'react'
import * as helper from '../../helpers/helpers';
import { connect } from 'react-redux'
import FuzzySearch from 'fuzzy-search'
import logoBlack from '../../assets/logo_black.svg'
import { MdAddCircleOutline } from 'react-icons/md'
import {
  setAuthVariable,
  logout
} from '../../redux/actions/authActions'
import {
  loadEverythnigForConnector,
  loadEverythingForTeam,
  setUsersVariable,
  setUsersVariables
} from '../../redux/actions/usersActions'


function mapStateToProps(state) {
  return {
    company: state.auth.company,
    connector: state.auth.connector,
    connectorList: state.users.connectorList,
    connectorName: state.users.connectorName,
    connectorCode: state.users.connectorCode,
    addNewConnector: state.auth.addNewConnector,
    addNewTeam: state.auth.addNewTeam,
    viewAccountPage: state.auth.viewAccountPage,
    currentInvite: state.auth.currentInvite,
    teamList: state.users.teamList,
    team: state.auth.team
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
    setUsersVariable: (fieldname, value) => {
      dispatch(setUsersVariable(fieldname, value))
    },
    setUsersVariables: object => {
      dispatch(setUsersVariables(object))
    },
    logout: () => {
      dispatch(logout())
    },
    loadEverythnigForConnector: connector => {
      dispatch(loadEverythnigForConnector(connector))
    },
    loadEverythingForTeam: team => {
      dispatch(loadEverythingForTeam(team))
    }
  }
}

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterText: ''
    }
  }

  signInClick = () => {
    this.props.setAuthVariable('page', 'sign_in')
  }

  continueClick = () => {
    this.props.setAuthVariable('page', 'join_email')
  }

  changeView = view => {
    this.props.setAuthVariable('view', view)
  }

  changeTeam = team => {
    if (this.props.team.name !== team.name) {
      this.props.loadEverythingForTeam(team);
      this.props.setAuthVariable('team', team)
      this.props.setAuthVariable('addNewTeam', false)
    }
  }

  changeConnector = connector => {
    this.props.setAuthVariable('viewAccountPage', false)
    if (this.props.connector.name !== connector.name) {
      this.props.setAuthVariable('connector', connector)
      this.props.loadEverythnigForConnector(connector)
      this.props.setAuthVariable('addNewConnector', false)
      this.props.setUsersVariables({
        connectorName: '',
        connectorCode: ''
      })
    }
  }

  addNewTeamClick = () => {
    this.props.setAuthVariable('addNewTeam', true)
    this.props.setAuthVariable('team', {})
  }

  addNewConnectorClick = () => {
    this.props.setAuthVariable('addNewConnector', true)
    this.props.setAuthVariable('connector', {})
  }

  addNewCompanyClick = () => {
    this.props.setAuthVariable('addNewCompany', true)
  }

  viewAccountPageClick = () => {
    this.props.setAuthVariable('viewAccountPage', true)
  }

  onSearchChange = event => {
    this.setState({
      filterText: event.target.value
    })
  }

  render() {
    const {
      company,
      connector,
      connectorList,
      connectorName,
      connectorCode,
      addNewConnector,
      addNewTeam,
      viewAccountPage,
      team,
      teamList
    } = this.props
    const { filterText } = this.state

    const searcher = new FuzzySearch(connectorList, ['name', 'code'], {
      caseSensitive: false
    })
    const connectorsFiltered = searcher.search(filterText)
    teamList.sort(helper.sortAlphabeticallyByName);

    return (
      <div className={'sidebar'}>
        <div>
          <br></br>
          <div className={'sidebar_padding'}>
            <h3>{company.name}</h3>
          </div>
          <br></br>
          <div className={'sidebar_padding'}>
            <input
              value={filterText}
              type='text'
              className={'sidebar_filter'}
              placeholder={'Filter connectors'}
              onChange={this.onSearchChange}
            />
          </div>
          <br></br>

          <div className={'section_header'}>
            <div className={'section_header_name'}>Connectors</div>
            <span onClick={() => this.addNewConnectorClick()}>
              <MdAddCircleOutline style={{ fontSize: '20px' }} />
            </span>
          </div>
        </div>
        <div className={'scroll stretch_connectors_container'}>
          {_.map(connectorsFiltered, row => {
            let current =
              connector.name === row.name && !viewAccountPage ? true : false
            return (
              <div key={row.code}>
                <div
                  className={`view_item ${current ? 'view_active' : ''}`}
                  onClick={() => this.changeConnector(row)}
                >
                  <div>
                    {row.name} - {row.code}
                    {/* {row.name} */}
                  </div>
                </div>
                {current ? (
                  <div className={'team_list'}>
                    {_.map(teamList, item => {
                      return (
                        <div
                          key={item.id}
                          onClick={() => this.changeTeam(item)}
                          className={`team_list_item ${
                            item.id === team.id && !addNewTeam
                              ? 'team_list_item_active'
                              : ''
                          }`}
                        >
                          {item.name}
                        </div>
                      )
                    })}
                    <div
                      className={`team_list_item ${
                        addNewTeam ? 'team_list_item_active' : ''
                      }`}
                      onClick={() => this.addNewTeamClick()}
                    >
                      <div>Add group</div>&nbsp;
                      <MdAddCircleOutline
                        style={{ fontSize: '20px', marginTop: '-1px' }}
                      />
                    </div>
                    {/* <div className={'team_list_item'}>Add team</div> */}
                  </div>
                ) : null}

                {/* {row.name} */}
              </div>
            )
          })}
          {/* {_.map(connectorsFiltered, row => {
            return (
              <div key={row.code}>
                <div
                  className={`view_item ${
                    connector.name === row.name && !viewAccountPage
                      ? 'view_active'
                      : ''
                  }`}
                  onClick={() => this.changeConnector(row)}
                >
                  {row.name} - {row.code}
                </div>
              </div>
            )
          })} */}
          {addNewConnector && (connectorName || connectorCode) ? (
            <div
              className={`view_item ${
                addNewConnector && (connectorName || connectorCode)
                  ? 'view_active'
                  : ''
              }`}
            >
              {connectorName} - {connectorCode}
            </div>
          ) : null}

          <br></br>
        </div>

        <div className={'center logo_container'}>
          <img src={logoBlack} width='180' alt='logo' />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)

// <table className={'color_row'}>
//   <tbody>
//     <tr>
//       <td className={'black'}></td>
//       <td className={'forestgreen'}></td>
//       <td className={'forestgreen'}></td>
//       <td className={'black'}></td>
//       <td className={'white'}></td>
//       <td className={'forestgreen'}></td>
//       <td className={'forestgreen'}></td>
//       <td className={'forestgreen'}></td>
//       <td className={'forestgreen'}></td>
//       <td className={'forestgreen'}></td>
//
//       <td className={'green'}></td>
//       <td className={'blue'}></td>
//
//       <td className={'red'}></td>
//       <td className={'five orange'}></td>
//       <td className={'yellow'}></td>
//       <td className={'white'}></td>
//       <td className={'white'}></td>
//       <td className={'white'}></td>
//       <td className={'white'}></td>
//       <td className={'five blue'}></td>
//
//       <td className={'blue'}></td>
//       <td className={'five lightblue'}></td>
//       <td className={'lightpurple'}></td>
//       <td className={'purple'}></td>
//       <td className={'forestgreen'}></td>
//       <td className={'lightblue'}></td>
//     </tr>
//   </tbody>
// </table>
