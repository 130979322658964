import React, { Component } from 'react'
// import _ from 'lodash'
import { connect } from 'react-redux'
import GroupMembersList from './GroupMembersList'
import { setAuthVariable } from '../../redux/actions/authActions'

function mapStateToProps(state) {
  return {
    company: state.auth.company,
    connector: state.auth.connector,
    teamMembers: state.users.teamMembers,
    newTeamMembersMap: state.users.newTeamMembersMap,
    newTeamNameMap: state.users.newTeamNameMap,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    }
  }
}

class NewTeam extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onClose = () => {
    this.props.setAuthVariable('addNewTeam', false)
  }

  render() {
    const { company, connector } = this.props

    return (
      <div>
        <br></br>
        {/* <button onClick={this.onClose}>
          close
        </button>
        <br></br> */}
        <br></br>
        <h2>New team for {company.name}, {connector.name}</h2>
        
        <GroupMembersList />
        
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTeam)
