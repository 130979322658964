// import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Users from './Users'
import { MdClose } from "react-icons/md";
import { setAuthVariable, logout } from '../../redux/actions/authActions'
// import * as helpers from '../../helpers/helpers'

function mapStateToProps(state) {
  return {
    view: state.auth.view,
    questionList: state.users.questionList,
    user: state.auth.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
    logout: () => {
      dispatch(logout())
    }
  }
}

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  signInClick = () => {
    this.props.setAuthVariable('page', 'sign_in')
  }

  continueClick = () => {
    this.props.setAuthVariable('page', 'join_email')
  }

  viewAccountPageClick = () => {
    this.props.setAuthVariable('viewAccountPage', false)
  }

  render() {
    const { user, logout } = this.props

    return (
      <div>
        <div className={'right'}>
          {/* close */}
          <MdClose style={{ fontSize: '24px'}} className={'pointer'} onClick={this.viewAccountPageClick}/>
        </div>
        <br></br>
        <br></br>
        <hr></hr>
        <br></br>
        <h2>Account</h2>
        <br></br>

        <div className={'view_row'}>{user.email}</div>
        <br></br>
        <br></br>
        <button className={'red_button'} onClick={logout}>
          sign out
        </button>
        <br></br>
        <br></br>
        <br></br>
        <h2>Support</h2>
        <br></br>
        <a href={'https://forms.gle/iTZ9uq6dfPCKFjEQ8 '} target={'_blank'} className={'link'}>Contact support</a>
        <br></br>
        <br></br>
        <Users />
        <br></br>
        <br></br>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account)
