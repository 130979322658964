import React, { Component } from 'react'
import { connect } from 'react-redux'
import logoBlack from '../../assets/logo_black.svg'
// import * as helper from '../../helpers/helpers'
import {
  setAuthVariable,
  startNewCompany,
  logout,
} from '../../redux/actions/authActions'

function mapStateToProps(state) {
  return {
    companyName: state.auth.companyName,
    companyNameError: state.auth.companyNameError,
    companyNameLoading: state.auth.companyNameLoading,
    path: state.auth.path,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
    startNewCompany: () => {
      dispatch(startNewCompany())
    },
    logout: () => {
      dispatch(logout())
    }
  }
}

class CreateCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resent: false
    }
  }

  onSubmit = event => {
    this.props.startNewCompany();
    event.preventDefault()
  }

  onNameChange = event => {
    this.props.setAuthVariable('companyNameError', '')
    this.props.setAuthVariable('companyName', event.target.value)
  }

  onBackClick = () => {
    this.props.setAuthVariable('page', 'sign_up')
  }


  render() {
    const { path, companyName, companyNameError, logout, companyNameLoading } = this.props;
    let disabled = true;
    if (companyName) {
      disabled = false;
    }
    // console.log('PATH =>', path)
    return (
      <div className='center gray_background'>
        <br></br>
        <br></br>
        <br></br>
        {/* <h1>enter new company name</h1> */}
        <div className={'card margin_auto sign_in_card'}>
          {path === 'start' ? (
            <div>
              <br></br>
              <img src={logoBlack} width='220' alt='logo'/>
              <br></br>
              <br></br>
              <div>Please enter company name</div>
              <br></br>
            </div>
          ):(
            <div>
              <br></br>
              <h2>Uh, oh</h2>
              Looks like we don't have an invite for you. Please contact admin to get an invite. 
              <br></br>
              <br></br>
              <button onClick={logout} className={'yellow_button'}>ok</button>
              <br></br>
              <br></br> 
              <br></br>
              <hr></hr>
              <br></br>
              {/* <div style={{ height: '1px', width: '400px', margin: 'auto', backgroundColor: 'black'}}></div> */}
              <br></br>
              <br></br> 
              <div>Did you want to start a company?</div>
              <br></br>
            </div>
          )}
          <br></br>
          <form onSubmit={this.onSubmit}>
            <input
              value={companyName}
              type='text'
              placeholder='Company name'
              name='company_name'
              onChange={this.onNameChange}
            />
            <br></br>
            <br></br>
            <button type='submit' className={'green_button'} disabled={disabled}>
              {companyNameLoading ? 'Loading...' : 'start new company'}
            </button>
          </form>
          <br></br>
          <div className={'red_font'}>{companyNameError}</div>
          </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCompany)
