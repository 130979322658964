import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  addConnectorToInvite,
  removeConnectorFromInvite
} from '../../redux/actions/usersActions'

function mapStateToProps(state) {
  return {
    inviteList: state.users.inviteList,
    connectorInvites: state.users.connectorInvites,
    connector: state.auth.connector,

    grantingAccessId: state.users.grantingAccessId,
    revokingAccessId: state.users.revokingAccessId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addConnectorToInvite: (invite, connector) => {
      dispatch(addConnectorToInvite(invite, connector))
    },
    removeConnectorFromInvite: (invite, connector) => {
      dispatch(removeConnectorFromInvite(invite, connector))
    }
  }
}

function formatNonConnectorUsers(connectorInvites, inviteList) {
  const nonConnectorUsers = []
  for (let user of inviteList) {
    let found = false
    for (let connectorInvite of connectorInvites) {
      if (connectorInvite.email === user.email) {
        found = true
      }
    }
    if (!found) {
      nonConnectorUsers.push(user)
    }
  }
  return nonConnectorUsers
}

class ConnectorAccess extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUser: {}
    }
  }

  onRevokeAccess = invite => {
    const { connector } = this.props
    this.props.removeConnectorFromInvite(invite, connector)
  }

  onGrantAccess = user => {
    const { connector } = this.props
    this.props.addConnectorToInvite(user, connector)
  }

  onUserClick = user => {
    const { currentUser } = this.state
    if (currentUser.email === user.email) {
      this.setState({
        currentUser: {}
      })
    } else {
      this.setState({
        currentUser: user
      })
    }
  }

  render() {
    const { inviteList, connectorInvites, connector, grantingAccessId, revokingAccessId } = this.props
    const users = formatNonConnectorUsers(connectorInvites, inviteList)

    return (
      <div>
        <br></br>
        <br></br>
        {/* <h2>Connector Invites</h2>
        <br></br> */}
        {/* <br></br> */}
        <div className={'card'}>
          <h3>Access Granted</h3>
          <div className={'spacer_10'}></div>
          <div>
            Users with access can view all groups attached to{' '}
            <span className={'bold'}>{connector.name}</span>.
          </div>
          <br></br>
          <table style={{ width: '100%' }}>
            <thead>
              <tr className={'table_header_row'}>
                <th className={'table_header_item'}>Email</th>
                <th></th>
              </tr>
            </thead>
            {_.map(connectorInvites, invite => {
              return (
                <tbody key={invite.id} className={'table_row'}>
                  <tr onClick={() => this.onUserClick(invite)}>
                    <td className={'table_item'}>{invite.email}</td>
                    <td className={'table_item pointer right'} onClick={() => this.onRevokeAccess(invite)}>
                      {revokingAccessId === invite.id ? 'Loading...' : 'Revoke Access'}
                    </td>
                  </tr>
                  {/* {currentUser.email === invite.email && (
                    <tr>
                      <td className={'table_item'}>{invite.email}</td>
                      
                    </tr>
                  )} */}
                </tbody>
              )
            })}
          </table>

          <br></br>
          <br></br>
          <h3>No Access Granted</h3>
          <br></br>
          <table style={{ width: '100%' }}>
            <thead>
              <tr className={'table_header_row'}>
                <th className={'table_header_item'}>Email</th>
                <th className={'table_header_item'}></th>
              </tr>
            </thead>
            {_.map(users, user => {
              return (
                <tbody key={user.id} className={'table_row'}>
                  <tr onClick={() => this.onUserClick(user)}>
                    <td className={'table_item'}>{user.email}</td>
                    <td className={'table_item pointer right'} onClick={() => this.onGrantAccess(user)}>
                      {grantingAccessId === user.id ? 'Loading...' : 'Grant Access'}
                    </td>
                    
                  </tr>
                  {/* {currentUser.email === user.email && (
                    <tr>
                      <td className={'table_item right'}>
                        <button className={'blue_button'} onClick={() => this.onGrantAccess(user)}>
                          invite
                        </button>
                      </td>
                    </tr>
                  )} */}
                </tbody>
              )
            })}
          </table>
        </div>
        <br></br>
        <br></br>
        <br></br>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectorAccess)
