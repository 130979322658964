import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setAuthVariable } from '../../redux/actions/authActions'
import { createFakeAnswers } from '../../redux/actions/usersActions'
// import people from '../../assets/people.svg'
import people2 from '../../assets/lady_dashboard_man_computer.svg'
import AveragesList from './AveragesList'
// import AverageCategories from './AverageCategories'
// import AverageOverall from './AverageOverall'
import Average from './Average'

function mapStateToProps(state) {
  return {
    loadingDashboard: state.auth.loadingDashboard,
    loadingQuestions: state.auth.loadingQuestions,
    loadingAnswers: state.auth.loadingAnswers,
    answerList: state.users.answerList,
    answerMap: state.users.answerMap,
    connector: state.auth.connector,
    team: state.auth.team,
    highestScores: state.users.highestScores,
    lowestScores: state.users.lowestScores,
    sortedAverages: state.users.sortedAverages,
    overallAverage: state.users.overallAverage,
    averagesByCategory: state.users.averagesByCategory
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
    createFakeAnswers: () => {
      dispatch(createFakeAnswers())
    }
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  signInClick = () => {
    this.props.setAuthVariable('page', 'sign_in')
  }

  continueClick = () => {
    this.props.setAuthVariable('page', 'join_email')
  }

  render() {
    const {
      loadingDashboard,
      loadingQuestions,
      loadingAnswers,
      answerList,
      connector,
      team,
      answerMap,
      highestScores,
      lowestScores,
      sortedAverages,
      overallAverage,
      averagesByCategory
    } = this.props
    
    // let hasLoaded = false
    // if (answerList.length > 0 && !loadingDashboard) {
    //   hasLoaded = true
    // }
    let message = ''
    if(answerMap[team.id] && answerMap[team.id].length > 0) {
      // has loaded, no message needed
    }else if(answerMap[team.id]) {
      message = 'No responses found';
      if(connector.name) {
        message += ' for ' + connector.name + ', ' + team.name
      }
    }else if(loadingAnswers) {
      message = 'Loading answers...';
    }else if(loadingQuestions) {
      message = 'Loading questions...';
    }else if(answerList.length === 0) {
      message = 'Loading...';
    }else if(loadingDashboard) {
      message = 'Loading...';
    }

    // hasLoaded = true

    // loading hasLoaded
    //   picture
    //   loading
    // !loading !hasLoaded
    //   picture
    //   not loading
    // loading !hasLoaded
    //   picture
    //   loading
    // !loading hasLoaded
    //   show dashboard

    return (
      <div>
        <div className={'center'}>
          <br></br>
          <br></br>
          {/* <button className={'yellow_button'} onClick={this.props.createFakeAnswers}>make fake answers</button> */}

          {/* <div style={{ backgroundColor: 'black', padding: '100px' }}>
            <div style={{ borderBottom: 'solid white 5px', color: 'white'}}>
                <h1>PB</h1>
            </div>
          </div> */}

          {!message ? (
            <div>
              <div className={'flex_wrap_row'}>
                <Average title={'Overall'} score={overallAverage} />
                {_.map(averagesByCategory, row => {
                  return (
                    <Average 
                      key={row.category}
                      title={row.category}
                      score={row.average}
                      />
                  )
                })}
              </div>
              {/* <AverageOverall sizeClass={'half_width'} score={overallAverage} /> */}
              {/* <AverageCategories
                sizeClass={'half_width'}
                rows={averagesByCategory}
              /> */}
              <div className={'spacer_10'}></div>
              {/* <hr></hr> */}
              <div className={'spacer_10'}></div>
              <div className={'flex_space_between'}>
                <div className={'half_width'}>
                  <AveragesList
                    rows={highestScores}
                    sizeClass={'full_width'}
                    title={'Highest Averages'}
                  />
                </div>
                <div className={'half_width'}>
                  <AveragesList
                    rows={lowestScores}
                    sizeClass={'full_width'}
                    title={'Lowest Averages'}
                  />
                </div>
              </div>
              <AveragesList
                rows={sortedAverages}
                sizeClass={'full_width'}
                title={'Averages'}
                showDetails={true}
                answers={answerList}
              />

              {/* <h2>dashboard parts will be here</h2> */}
              {/* <div>Last 90 days: company engagement, overall average</div>
                <div>Last 90 days: averages by category</div> */}
              {/* <div>Last 90 days: highest weight averages</div> */}
              {/* <div>Last 90 days: lowest weight averages</div> */}
              {/* <div>Last 90 days: all questions and averages</div> */}
              {/* <div>Last 90 days: overall average by date</div>
              <div>Last 90 days: category average by date</div> */}
            </div>
          ) : (
            <div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <h2>{message}</h2>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <img src={people2} height='300' alt='logo' />
              {/* {loadingDashboard ? (
                <div>
                  <h2>Dashboard loading...</h2>
                </div>
              ) : (
                <div>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              )} */}
              <br></br>
              <br></br>
            </div>
          )}
        </div>

        {/* <img src={people} className="App-logo" alt="logo" /> */}
        <br></br>
        <br></br>
        <br></br>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
