import _ from 'lodash'
import * as helper from '../../helpers/helpers'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { runQuery, runMutation } from './gqlHelper'

export async function getTeamsByConnectorId(connectorId) {
  let teams = []
  let body = {
    connectorId: connectorId
  }
  let more = true
  while (more) {
    more = false
    const resultObj = await runQuery(queries.teamByConnectorId, body)
    const result = resultObj.data.teamByConnectorId
    teams = teams.concat(result.items)
    if (result.nextToken) {
      more = true
      body.nextToken = result.nextToken
    }
  }
  return teams;
}

export async function createGeneralTeam(companyId, connectorId) {
  const input = {
    input: {
      name: 'General',
      deletable: false,
      isDefault: true,
      timestamp: helper.getNowTimestamp(),
      companyId: companyId,
      connectorId: connectorId,
      members: [],
    }
  }
  const result = await runMutation(mutations.createTeam, input)
  return result.data.createTeam
}

export async function createNewTeam(companyId, connectorId, name) {
  const input = {
    input: {
      name: name,
      deletable: true,
      isDefault: false,
      timestamp: helper.getNowTimestamp(),
      companyId: companyId,
      connectorId: connectorId,
      members: []
    }
  }
  const result = await runMutation(mutations.createTeam, input)
  return result.data.createTeam
}

export async function updateTeamName(team, name) {
  const input = {
    input: {
      id: team.id,
      name: name,
      deletable: team.deletable,
      isDefault: team.isDefault,
      timestamp: team.timestamp,
      members: team.members,
      companyId: team.companyId,
      connectorId: team.connectorId
    }
  }
  const result = await runMutation(mutations.updateTeam, input)
  return result.data.updateTeam
}
export async function updateTeamMembers(team, teamList, members, allMembers) {
  let defaultTeam = null
  for (let t of teamList) {
    if (t.isDefault) {
      defaultTeam = t
    }
  }
  // console.log('TEAMMMM', team)
  const newAllMembers = _.cloneDeep(allMembers)
  _.remove(newAllMembers, function(m) {
    return m.teamId !== team.id
  })
  const toRemove = []
  for (let m of newAllMembers) {
    let found = false
    for (let n of members) {
      if (m.id === n.id) {
        found = true
      }
    }
    if (!found) {
      toRemove.push(m)
    }
  }

  let promises = []
  for (let member of members) {
    if (member.teamId !== team.id) {
      const input = {
        input: {
          id: member.id,
          name: member.name,
          email: member.email,
          profile: member.profile,
          role: member.role,
          manager: member.manager,
          teamId: team.id,
          connectorId: member.connectorId
        }
      }
      promises.push(runMutation(mutations.updateTeamMember, input))
    }
  }
  for (let member of toRemove) {
    if (defaultTeam) {
      const input = {
        input: {
          id: member.id,
          name: member.name,
          email: member.email,
          profile: member.profile,
          role: member.role,
          manager: member.manager,
          teamId: defaultTeam.id,
          connectorId: member.connectorId
        }
      }
      promises.push(runMutation(mutations.updateTeamMember, input))
    }
  }
  return Promise.all(promises)
}