/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      start
      invites {
        items {
          id
          email
          group
          accepted
          revoked
          timestamp
          companyId
          connectors
          createdAt
          updatedAt
        }
        nextToken
      }
      connectors {
        items {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvite = /* GraphQL */ `
  query GetInvite($id: ID!) {
    getInvite(id: $id) {
      id
      email
      group
      accepted
      revoked
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      connectors
      createdAt
      updatedAt
    }
  }
`;
export const listInvites = /* GraphQL */ `
  query ListInvites(
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        group
        accepted
        revoked
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectors
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConnector = /* GraphQL */ `
  query GetConnector($id: ID!) {
    getConnector(id: $id) {
      id
      type
      code
      name
      status
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listConnectors = /* GraphQL */ `
  query ListConnectors(
    $filter: ModelConnectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      name
      email
      profile
      role
      manager
      teamId
      connectorId
      connector {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        profile
        role
        manager
        teamId
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConnectorCode = /* GraphQL */ `
  query GetConnectorCode($id: ID!) {
    getConnectorCode(id: $id) {
      id
      code
      createdAt
      updatedAt
    }
  }
`;
export const listConnectorCodes = /* GraphQL */ `
  query ListConnectorCodes(
    $filter: ModelConnectorCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectorCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      deletable
      isDefault
      timestamp
      companyId
      company {
        id
        name
        start
        invites {
          nextToken
        }
        connectors {
          nextToken
        }
        createdAt
        updatedAt
      }
      connectorId
      connector {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      members
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deletable
        isDefault
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        members
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamQuestion = /* GraphQL */ `
  query GetTeamQuestion($id: ID!) {
    getTeamQuestion(id: $id) {
      id
      active
      nextAskDate
      pastAskDates
      question {
        id
        text
        category
        bank
        questionBankId
        options
        createdAt
        updatedAt
      }
      teamID
      team {
        id
        name
        deletable
        isDefault
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        members
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeamQuestions = /* GraphQL */ `
  query ListTeamQuestions(
    $filter: ModelTeamQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        nextAskDate
        pastAskDates
        question {
          id
          text
          category
          bank
          questionBankId
          options
          createdAt
          updatedAt
        }
        teamID
        team {
          id
          name
          deletable
          isDefault
          timestamp
          companyId
          connectorId
          members
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      text
      category
      bank
      questionBankId
      options
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        category
        bank
        questionBankId
        options
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      email
      timestamp
      answer
      extraText
      weight
      teamQuestionId
      teamQuestion {
        id
        active
        nextAskDate
        pastAskDates
        question {
          id
          text
          category
          bank
          questionBankId
          options
          createdAt
          updatedAt
        }
        teamID
        team {
          id
          name
          deletable
          isDefault
          timestamp
          companyId
          connectorId
          members
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        timestamp
        answer
        extraText
        weight
        teamQuestionId
        teamQuestion {
          id
          active
          nextAskDate
          pastAskDates
          teamID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionBank = /* GraphQL */ `
  query GetQuestionBank($id: ID!) {
    getQuestionBank(id: $id) {
      id
      name
      description
      public
      companyId
      teamId
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionBanks = /* GraphQL */ `
  query ListQuestionBanks(
    $filter: ModelQuestionBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionBanks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        public
        companyId
        teamId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invitesByEmail = /* GraphQL */ `
  query InvitesByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitesByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        group
        accepted
        revoked
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectors
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invitesByCompanyId = /* GraphQL */ `
  query InvitesByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitesByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        group
        accepted
        revoked
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectors
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const connectorByCode = /* GraphQL */ `
  query ConnectorByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelConnectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectorByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        code
        name
        status
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByEmail = /* GraphQL */ `
  query TeamMemberByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        profile
        role
        manager
        teamId
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMembersByConnectorId = /* GraphQL */ `
  query TeamMembersByConnectorId(
    $connectorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMembersByConnectorId(
      connectorId: $connectorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        profile
        role
        manager
        teamId
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByCompanyId = /* GraphQL */ `
  query TeamByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        deletable
        isDefault
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        members
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByConnectorId = /* GraphQL */ `
  query TeamByConnectorId(
    $connectorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByConnectorId(
      connectorId: $connectorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        deletable
        isDefault
        timestamp
        companyId
        company {
          id
          name
          start
          createdAt
          updatedAt
        }
        connectorId
        connector {
          id
          type
          code
          name
          status
          timestamp
          companyId
          createdAt
          updatedAt
        }
        members
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamQuestionsByTeamId = /* GraphQL */ `
  query TeamQuestionsByTeamId(
    $teamID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamQuestionsByTeamId(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        nextAskDate
        pastAskDates
        question {
          id
          text
          category
          bank
          questionBankId
          options
          createdAt
          updatedAt
        }
        teamID
        team {
          id
          name
          deletable
          isDefault
          timestamp
          companyId
          connectorId
          members
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByBank = /* GraphQL */ `
  query QuestionsByBank(
    $bank: String
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByBank(
      bank: $bank
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        category
        bank
        questionBankId
        options
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByQuestionBankId = /* GraphQL */ `
  query QuestionsByQuestionBankId(
    $questionBankId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByQuestionBankId(
      questionBankId: $questionBankId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        category
        bank
        questionBankId
        options
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const answerByTeamQuestionId = /* GraphQL */ `
  query AnswerByTeamQuestionId(
    $teamQuestionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answerByTeamQuestionId(
      teamQuestionId: $teamQuestionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        timestamp
        answer
        extraText
        weight
        teamQuestionId
        teamQuestion {
          id
          active
          nextAskDate
          pastAskDates
          teamID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
