import _ from 'lodash'
import * as helper from '../../helpers/helpers'
// import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
// import { runQuery, runMutation } from './gqlHelper'
import { runMutation } from './gqlHelper'

export async function createRandomAnswersForUser(teamQuestionList) {
  // id: ID
  // email: String!
  // timestamp: Int
  // answer: String!
  // extraText: String
  // weight: Float
  // teamQuestionId: ID!

  const email = 'kenroczen@gmail.com';
  const howMany = 3;
  
  
  let promises = [];
  for(let q of teamQuestionList) {
    // console.log(q.question.options);
    let size = q.question.options.length;
    let rand = _.random(0, size - 1);
    // console.log('size: ', size)
    // console.log('rand: ', rand)
    let picked = q.question.options[rand]
    let weightLong = (size - 1 - rand) * (100.00 / (size - 1));
    let weight = weightLong.toFixed(2);
    // console.log('weight: ', weight)

    let timestamp = helper.getTodayMidnightUTC();
    for(let i = 0; i < howMany; i++) {
      const input = {
        input: {
          email: email,
          timestamp: timestamp,
          answer: picked,
          // extraText: '',
          weight: weight,
          teamQuestionId: q.id
        }
      }
      // console.log(input.input);
      await runMutation(mutations.createAnswer, input);
      // promises.push(runMutation(mutations.createAnswer, input));
      timestamp -= 86400;
    }
  }
  return Promise.all(promises);
}


// export function createQuestionsCgaps() {
//   return async function(dispatch, getState) {
//     const options = [
//       'Strongly Agree',
//       'Moderately Agree',
//       'Slightly Agree',
//       'Neutral',
//       'Slightly Disagree',
//       'Moderately Disagree',
//       'Strongly Disagree',
//     ]
//     // const optionsReverse = [
//     //   'Strongly Disagree',
//     //   'Moderately Disagree',
//     //   'Slightly Disagree',
//     //   'Neutral',
//     //   'Slightly Agree',
//     //   'Moderately Agree',
//     //   'Strongly Agree',
//     // ]
//     const questions = [
//       {
//         text: "As our processes get more complex I am confident Salesforce will be the right long term solution",
//         category: 'Process',
//       },
//       {
//         text: "I have recommendations on how to improve my workflow in Salesforce",
//         category: 'Adoption',
//       },
//       {
//         text: "I've adopted and embraced Salesforce as the right solution for me",
//         category: 'Governance',
//       },
//       {
//         text: "I need further assistance on how to use Salesforce",
//         category: 'Adoption',
//       },
//       {
//         text: "I felt adequately prepared for the roll out of our new process(es).",
//         category: 'Scalability',
//       },
//       {
//         text: "Expectations for the roll out and work flows were clearly set prior to go-live.",
//         category: 'Scalability',
//       },
//       {
//         text: "I know the operating cost of the new solution",
//         category: 'Governance',
//       },
//       {
//         text: "The procedures are properly documented and known ",
//         category: 'Governance',
//       },
//       {
//         text: "I found that the consultant understood my process",
//         category: 'Scalability',
//       },
//       {
//         text: "I would use the same Salesforce consultants again",
//         category: 'Scalability',
//       },
//       {
//         text: "I like using Salesforce for my work",
//         category: 'Process',
//       },
//       {
//         text: "I find the new process better than our last process",
//         category: 'Governance',
//       },
//       {
//         text: "I am satisfied with how I use Salesforce",
//         category: 'Adoption',
//       },
//       {
//         text: "I view Salesforce as a tool rather than a burden",
//         category: 'Process',
//       },
//       {
//         text: "I thought our Salesforce roll out was done professionally and was well done",
//         category: 'Process',
//       },
//       {
//         text: "I feel comfortable recommending the consulting services we used to a friend",
//         category: 'Adoption',
//       }
//     ]
//     try {
//       const promises = [];
//       for(let q of questions) {
//         let input = {
//           input: {
//             text: q.text,
//             category: q.category, 
//             bank: 'cgaps',
//             options: options
//           }
//         }
//         promises.push(runMutation(mutations.createQuestion, input))
//         // await runMutation(mutations.createQuestion, input)
//       }
//       const results = await Promise.all(promises);
//       console.log(results);
    
//     } catch (error) {
//       console.log(error)
//     }
//   }
// }