import * as a from '../constants/actions';

const INITIAL_STATE = {
  inviteList: [],
  answerList: [],
  answerMap: {},
  inviteEmail: '',
  teamList: [],
  teamMap: {},
  connectorList: [],
  questionList: [],
  questionMap: {},
  
  bankList: [],
  bankListMap: {},
  bankQuestionList: [],
  bankQuestionListMap: {},

  connectorInvites: [],
  teamMembers: [],
  newTeamMembersMap: {},
  newTeamNameMap: {},

  connectorName: '',
  connectorCode: '',
  connectorError: '',
  connectorBank: 'pride',

  sortedAverages: [],
  // sortedAverages: [{"id":"7775bd8c-1598-429c-8575-2988818b65f1","text":"I have purpose at work.","answers":[1,1,1],"average":"1.00","class":"bad_average"},{"id":"a166ca66-ef60-4d76-b86a-c5ae0394d4dc","text":"I have opportunities for career advancement.","answers":[1,1,1],"average":"1.00","class":"bad_average"},{"id":"3811225e-eea8-4332-9039-677ad9d185a7","text":"I am free to express myself at work.","answers":[1,2,1],"average":"1.33","class":"bad_average"},{"id":"b8e86f19-ef56-4302-bb96-80e1423df91a","text":"What I am working on adds value.","answers":[1,1,4],"average":"2.00","class":"neutral_average"},{"id":"5ae0181d-6ca8-4f87-90ad-2681d4ec7eda","text":"I feel stuck in my job.","answers":[2,2,2],"average":"2.00","class":"neutral_average"},{"id":"932be4b4-7563-4fb3-8861-370d1772dc61","text":"I feel empowered at work.","answers":[2,2,2],"average":"2.00","class":"neutral_average"},{"id":"6d12ec13-94d4-47d1-ab41-5590188a5322","text":"My manager cares about my success.","answers":[4,3,1],"average":"2.67","class":"neutral_average"},{"id":"0d63ec84-234c-4b9c-be41-0d8624d958e2","text":"I like my co-workers.","answers":[3,3,3],"average":"3.00","class":"neutral_average"},{"id":"3f179adf-6cf0-4f6d-b32e-547ef9ed6273","text":"My boss knows my needs.","answers":[2,5,2],"average":"3.00","class":"neutral_average"},{"id":"300acfcf-306c-4963-b335-a36f9de22c95","text":"I know what the company goals are.","answers":[1,5,4],"average":"3.33","class":"good_average"},{"id":"0a93c48c-a702-40a0-a424-60f0314a1a32","text":"My co-workers listen to my ideas.","answers":[3,5,5,4,2],"average":"3.80","class":"good_average"},{"id":"da33da22-eab4-48d5-9a6e-211faa43a6c3","text":"I like what I am working on.","answers":[5,5,5],"average":"5.00","class":"good_average"}],
  lowestScores: [],
  // lowestScores: [{"id":"7775bd8c-1598-429c-8575-2988818b65f1","text":"I have purpose at work.","answers":[1,1,1],"average":"1.00","class":"bad_average"},{"id":"a166ca66-ef60-4d76-b86a-c5ae0394d4dc","text":"I have opportunities for career advancement.","answers":[1,1,1],"average":"1.00","class":"bad_average"},{"id":"3811225e-eea8-4332-9039-677ad9d185a7","text":"I am free to express myself at work.","answers":[1,2,1],"average":"1.33","class":"bad_average"},{"id":"b8e86f19-ef56-4302-bb96-80e1423df91a","text":"What I am working on adds value.","answers":[1,1,4],"average":"2.00","class":"neutral_average"},{"id":"5ae0181d-6ca8-4f87-90ad-2681d4ec7eda","text":"I feel stuck in my job.","answers":[2,2,2],"average":"2.00","class":"neutral_average"}],
  highestScores: [],
  // highestScores: [{"id":"da33da22-eab4-48d5-9a6e-211faa43a6c3","text":"I like what I am working on.","answers":[5,5,5],"average":"5.00","class":"good_average"},{"id":"0a93c48c-a702-40a0-a424-60f0314a1a32","text":"My co-workers listen to my ideas.","answers":[3,5,5,4,2],"average":"3.80","class":"good_average"},{"id":"300acfcf-306c-4963-b335-a36f9de22c95","text":"I know what the company goals are.","answers":[1,5,4],"average":"3.33","class":"good_average"},{"id":"3f179adf-6cf0-4f6d-b32e-547ef9ed6273","text":"My boss knows my needs.","answers":[2,5,2],"average":"3.00","class":"neutral_average"},{"id":"0d63ec84-234c-4b9c-be41-0d8624d958e2","text":"I like my co-workers.","answers":[3,3,3],"average":"3.00","class":"neutral_average"}],
  overallAverage: '0',
  averagesByCategory: [],
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case a.CLEAR_SESSION: {
			return { ...INITIAL_STATE }
		}
		case a.SET_USERS_VARIABLE: {
			if(action.payload.fieldname) {
				state[action.payload.fieldname] = action.payload.value;
			}
			return { ...state }
    }
    case a.SET_USERS_VARIABLES: {
			return { ...state, ...action.payload }
		}
		default: {
			return { ...state }
		}
	}
}

