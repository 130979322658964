import React, { Component } from 'react';
import { connect } from 'react-redux';
import NewSalesforceConnector from './NewSalesforceConnector';

function mapStateToProps(state) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {}
}

class NewConnector extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  
  render() {	
    return (
      <div>
        <br></br>
        <NewSalesforceConnector />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewConnector);