import _ from 'lodash'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import CustomQuestionLocal from './CustomQuestionLocal'
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa'
import {MdEdit} from 'react-icons/md'
import * as helper from '../../helpers/helpers'
import Modal from 'react-modal'

import {
  updateOrCreateBank,
  loadQuestionsForBank,
  updateQuestion,
  createQuestion,
  uninstallBankFromTeam,
  downloadBankToTeam,
} from '../../redux/actions/usersActions'

Modal.setAppElement('#root')

function mapStateToProps(state) {
  return {
    team: state.auth.team,
    teamMembers: state.users.teamMembers,

    questionList: state.users.questionList,

    bankList: state.users.bankList,

    bankListMap: state.users.bankListMap,
    bankQuestionList: state.users.bankQuestionList,
    bankQuestionListMap: state.users.bankQuestionListMap,
    loadingBankQuestions: state.auth.loadingBankQuestions,

    uninstallingBank: state.auth.uninstallingBank,
    downloadingBank: state.auth.downloadingBank
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateOrCreateBank: (bank, questions) => {
      dispatch(updateOrCreateBank(bank, questions))
    },
    loadQuestionsForBank: bank => {
      dispatch(loadQuestionsForBank(bank))
    },
    updateQuestion: question => {
      dispatch(updateQuestion(question))
    },
    createQuestion: (question, bank) => {
      dispatch(createQuestion(question, bank))
    },
    uninstallBankFromTeam: (team, bank) => {
      dispatch(uninstallBankFromTeam(team, bank))
    },
    downloadBankToTeam: (team, bank) => {
      dispatch(downloadBankToTeam(team, bank))
    }
  }
}

function formatQuestions(questions, bankQuestionList) {
  let questionsClone = _.cloneDeep(questions)
  let bankQuestionListClone = _.cloneDeep(bankQuestionList)
  for (let q of questionsClone) {
    if (q.id) {
      _.remove(bankQuestionListClone, function (n) {
        return n.id === q.id
      })
    }
  }
  return questionsClone.concat(bankQuestionListClone)
}

function checkIfDownloaded(bank, questionList) {
  for (let q of questionList) {
    if (q.active && q.question.questionBankId === bank.id) {
      return true
    }
  }
  return false
}

class Marketplace extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      editBank: false,
      currentQuestion: {},
      addNewBank: false,
      // name: '',
      // description: '',
      bank: {
        name: '',
        description: '',
        shareWithCompany: false
      },
      questions: []
    }
  }

  onAddNewBank = () => {
    this.setState({
      addNewBank: true,
      editBank: false,
      bank: {
        name: '',
        description: '',
        shareWithCompany: false
      },
      questions: []
    })
  }

  onCancelNewBank = () => {
    this.setState({
      addNewBank: false,
      editBank: false,
      bank: {
        name: '',
        description: '',
        shareWithCompany: false
      },
      questions: []
    })
  }

  onUninstallBank = () => {
    const {bank} = this.state
    const {team} = this.props
    this.props.uninstallBankFromTeam(team, bank);
  }

  onDownloadBank = () => {
    const {bank} = this.state
    const {team} = this.props
    this.props.downloadBankToTeam(team, bank);
  }

  onSaveNewBank = () => {
    const {bank, questions} = this.state
    const bankCopy = _.cloneDeep(bank)
    const questionsCopy = _.cloneDeep(questions)
    this.props.updateOrCreateBank(bankCopy, questionsCopy)
    this.setState({
      addNewBank: false,
      editBank: false,
      bank: {
        name: '',
        description: '',
        shareWithCompany: false
      },
      questions: []
    })
  }

  onNameChange = event => {
    let bank = _.cloneDeep(this.state.bank)
    if (!bank.public) {
      bank.name = event.target.value
      this.setState({
        bank
      })
    }
  }

  onDescriptionChange = event => {
    let bank = _.cloneDeep(this.state.bank)
    if (!bank.public) {
      bank.description = event.target.value
      this.setState({
        bank
      })
    }
  }

  onCheckboxChange = event => {
    let bank = _.cloneDeep(this.state.bank)
    if (!bank.public) {
      bank.shareWithCompany = !bank.shareWithCompany
      this.setState({
        bank
      })
    }
  }

  onNewQuestion = questionObj => {
    let questions = _.cloneDeep(this.state.questions)
    // console.log('on new question marketplace')
    if (questionObj.question && questionObj.question.id) {
      for (let q of this.props.bankQuestionList) {
        if (q.id === questionObj.question.id) {
          q.text = questionObj.text
          q.options = questionObj.options
          q.updated = true
          // questions.push(q)
          // console.log('here 1')
          // console.log(q)
          // TODO update this question, load question list
          this.props.updateQuestion(q);
        }
      }
    } else if (questionObj.question && questionObj.question.text) {
      // editing a question that you just added
      for (let q of questions) {
        if (q.text === questionObj.question.text) {
          q.text = questionObj.text
          q.options = questionObj.options
          q.new = true
          // console.log('here 2')
          // console.log(q)
        }
      }
    } else {
      this.props.createQuestion(questionObj, this.state.bank)
    }
    // console.log(questionObj)
    this.setState({
      questions
    })
    this.closeModal()
  }

  onEditQuestion = question => {
    let questions = _.cloneDeep(this.state.questions)
    // console.log('on new question marketplace')
    questions.push(question)
    this.setState({
      questions
    })
    this.closeModal()
  }

  viewBankDetails = bank => {
    if (this.state.bank.id === bank.id) {
      this.setState({
        addNewBank: false,
        editBank: false
      })
    } else {
      if (!bank.name) {
        bank.name = ''
      }
      if (!bank.description) {
        bank.description = ''
      }
      this.props.loadQuestionsForBank(bank)
      this.setState({
        addNewBank: true,
        editBank: false,
        bank
      })
    }
  }

  viewQuestionDetails = question => {
    this.setState({
      modalIsOpen: true,
      currentQuestion: question
    })
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      currentQuestion: {}
    })
  }

  onNewCustomQuestionClick = () => {
    this.setState({
      modalIsOpen: true,
      currentQuestion: {}
    })
  }

  onEditClick = () => {
    this.setState({
      editBank: true
    })
  }

  render() {
    const {
      bankList,
      bankQuestionList,
      loadingBankQuestions,
      questionList,
      downloadingBank,
      uninstallingBank
    } = this.props
    const {
      addNewBank,
      questions,
      bank,
      modalIsOpen,
      currentQuestion,
      editBank
    } = this.state

    const disabled = !bank.name ? true : false
    const rows = formatQuestions(questions, bankQuestionList)
    const downloaded = checkIfDownloaded(bank, questionList)

    let editMode = false
    if (editBank || !bank.id) {
      editMode = true
    }

    return (
      <div>
        {/* <br></br>
        <h2>Marketplace</h2> */}

        <br></br>
        <br></br>
        {/* <br></br> */}
        <div style={{padding: 10}}>
          {!addNewBank ? (
            <button onClick={this.onAddNewBank}>
              Create custom question bank +
            </button>
          ) : (
              <div>
                <div className={'card'}>
                  {editMode ? (
                    <form onSubmit={this.onSaveNewBank}>
                      <div className={'flex_space_between align_top'}>
                        <div style={{width: 350}}>
                          <input
                            value={bank.name}
                            onChange={this.onNameChange}
                            placeholder='Bank name'
                            name='bank_name'
                          />
                          <div className={'spacer_10'}></div>
                          {/* <div className={'spacer_10'}></div> */}
                          <textarea
                            value={bank.description}
                            onChange={this.onDescriptionChange}
                            placeholder='Description'
                            name='bank_description'
                          />
                        </div>

                        <div>
                          {!bank.public ? (
                            <button
                              className={'blue_button'}
                              type='submit'
                              disabled={disabled}
                            >
                              save
                            </button>
                          ) : null}
                          <button onClick={this.onCancelNewBank}>cancel</button>
                        </div>
                      </div>
                    </form>
                  ) : (
                      <div>
                        <div className={'flex_space_between align_center'}>
                          <div className={'flex_wrap_row align_center'}>
                            <h2>{bank.name} &nbsp;</h2>
                            {downloaded ? (
                              <FaCheckCircle
                                style={{fontSize: '30px', color: '#1C915A'}}
                              />
                            ) : (
                                <FaTimesCircle
                                  style={{fontSize: '30px', color: '#d6d6d6'}}
                                />
                              )}
                            <span>&nbsp;&nbsp;</span>
                            {!bank.public && (
                              <MdEdit
                                style={{fontSize: '20px'}}
                                onClick={this.onEditClick}
                              />
                            )}
                          </div>
                          <div>
                            {downloaded ? (
                              <button
                                onClick={this.onUninstallBank}
                                className={'outline_red'}
                              >
                                {uninstallingBank ? 'Loading...' : 'uninstall'}
                              </button>
                            ) : (
                                <button
                                  onClick={this.onDownloadBank}
                                  className={'green_button'}
                                >
                                  {downloadingBank ? 'Loading...' : 'download'}
                                </button>
                              )}
                            <button
                              onClick={this.onCancelNewBank}
                              className={'outline margin_left_10'}
                            >
                              cancel
                        </button>
                          </div>
                        </div>
                        <br></br>
                        {bank.description ? (
                          <span>
                            <div>{bank.description}</div>
                            <br></br>
                          </span>
                        ) : null}
                      </div>
                    )}

                  <br></br>

                  <div className={'flex_space_between align_center'}>
                    <h3>Questions</h3>

                    {!bank.public && bank.id ? (
                      <button onClick={this.onNewCustomQuestionClick}>
                        add custom question +
                      </button>
                    ) : null}
                  </div>
                  <br></br>
                  {bank.id ? (
                    <div>
                      {!loadingBankQuestions ? (
                        <div>
                          {rows.length > 0 ? (
                            <div>
                              <table style={{width: '100%'}}>
                                <thead>
                                  <tr className={'table_header_row'}>
                                    <th>Statement</th>
                                    <th className={'table_header_item'}>
                                      Category
                                  </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rows.map((q, index) => {
                                    return (
                                      <tr
                                        key={index}
                                        onClick={() =>
                                          this.viewQuestionDetails(q)
                                        }
                                        className={'table_row pointer'}
                                      >
                                        <td className={'table_item'}>{q.text}</td>
                                        <td className={'table_item'}>
                                          {q.category}
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                              <div>
                                question bank doesn't have any questions yet
                              </div>
                            )}
                        </div>
                      ) : (
                          <div>Loading questions...</div>
                        )}
                    </div>
                  ) : (
                      <div><br></br>Click Save to start adding questions to bank.</div>
                    )}
                </div>
              </div>
            )}

          {/* <br></br> */}
          {/* <hr></hr> */}
          <br></br>
          <br></br>

          <div>
            <div className={'flex_wrap_row'}>
              {bankList.map((b, index) => {
                const downloaded = checkIfDownloaded(b, questionList)
                return (
                  <div key={b.id} style={{padding: 5, width: 300}}>
                    <div
                      className={'card skinny flex_space_between_column'}
                      style={{height: 140}}
                    >
                      <span>
                        <div className={'flex_wrap_row align_center'}>
                          <div
                            style={{
                              width: 'calc(100% - 30px)'
                            }}
                          >
                            <h3>{b.name}</h3>
                          </div>
                          <div style={{width: '30px'}}>
                            {downloaded ? (
                              <FaCheckCircle
                                style={{fontSize: '30px', color: '#1C915A'}}
                              />
                            ) : (
                                <FaTimesCircle
                                  style={{fontSize: '30px', color: '#d6d6d6'}}
                                />
                              )}
                          </div>
                        </div>
                        <div>{helper.limitLength(b.description, 100)}</div>
                      </span>

                      <div className={'right'}>
                        <button
                          className={'blue_button'}
                          onClick={() => this.viewBankDetails(b)}
                        >
                          details
                        </button>
                        {/* <div> */}
                        {/* {downloaded ? (
                          <button className={'red_button'}>remove</button>
                          // <div>details</div>
                        ) : (
                          <button className={'blue_button'} onClick={() => this.viewBankDetails(b)}>details</button>
                        )} */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          className={'modal_content'}
          overlayClassName={'modal_overlay'}
        >
          <CustomQuestionLocal
            onNewQuestion={this.onNewQuestion}
            question={currentQuestion}
            onCloseClick={this.closeModal}
          />
        </Modal>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Marketplace)
