import * as helper from '../../helpers/helpers'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { runQuery, runMutation } from './gqlHelper'

export async function getInviteById(inviteId) {
  let input = {
    id: inviteId
  }
  const resultObj = await runQuery(queries.getInvite, input)
  return resultObj.data.getInvite
}

export async function getInvitesByConnectorId(connectorId) {
  let body = {
    filter: {
      connectors: {
        contains: connectorId
      }
    }
  }
  let invites = []
  let more = true
  while (more) {
    more = false
    const resultObj = await runQuery(queries.listInvites, body)
    const result = resultObj.data.listInvites
    invites = invites.concat(result.items)
    if (result.nextToken) {
      more = true
      body.nextToken = result.nextToken
    }
  }
  invites.sort((a, b) => {
    if (a.email.toLowerCase() > b.email.toLowerCase()) {
      return 1
    } else {
      return -1
    }
  })
  return invites;
}

export async function getInvitesByCompanyId(companyId) {
  let body = {
    filter: {
      companyId: {
        eq: companyId
      }
    }
  }
  let more = true
  let invites = []
  while (more) {
    more = false
    const resultObj = await runQuery(queries.listInvites, body)
    const result = resultObj.data.listInvites
    invites = invites.concat(result.items)
    if (result.nextToken) {
      more = true
      body.nextToken = result.nextToken
    }
  }
  invites.sort((a, b) => {
    if (a.email.toLowerCase() > b.email.toLowerCase()) {
      return 1
    } else {
      return -1
    }
  })
  return invites;
}

export async function findInviteForUser(email, companyId) {
  let input = {
    email: email,
    filter: {
      companyId: {
        eq: companyId
      }
    }
  }
  const resultObj = await runQuery(queries.invitesByEmail, input)
  const result = resultObj.data.invitesByEmail
  if (result.items.length > 0) {
    return result.items[0];
  }
  return null;
}

export async function updateInvite(invite) {
  const updatedInvite = {
    input: {
      id: invite.id,
      email: invite.email,
      group: invite.group,
      accepted: invite.accepted,
      revoked: invite.revoked,
      timestamp: invite.timestamp,
      companyId: invite.companyId,
      connectors: invite.connectors,
      inviteCompanyId: invite.companyId
    }
  }
  const resultObj = await runMutation(mutations.updateInvite, updatedInvite);
  return resultObj.data.updateInvite;
}

export async function createNewMemberInvite(email, companyId) {
  const newInvite = {
    input: {
      email: email,
      timestamp: helper.getNowTimestamp(),
      companyId: companyId,
      inviteCompanyId: companyId,
      group: 'Member',
      connectors: []
    }
  }
  const resultObj = await runMutation(mutations.createInvite, newInvite)
  return resultObj.data.createInvite;
}

export async function revokeInvite(invite) {
  const updatedInvite = {
    input: {
      id: invite.id,
      email: invite.email,
      group: invite.group,
      accepted: invite.accepted,
      revoked: true,
      timestamp: invite.timestamp,
      companyId: invite.companyId,
      connectors: invite.connectors,
      inviteCompanyId: invite.companyId
    }
  }
  const resultObj = await runMutation(mutations.updateInvite, updatedInvite);
  return resultObj.data.updateInvite;
}

export async function updateInviteGroup(invite, group) {
  const updatedInvite = {
    input: {
      id: invite.id,
      email: invite.email,
      group: group,
      accepted: invite.accepted,
      revoked: invite.revoked,
      timestamp: invite.timestamp,
      companyId: invite.companyId,
      connectors: invite.connectors,
      inviteCompanyId: invite.companyId
    }
  }
  const resultObj = await runMutation(mutations.updateInvite, updatedInvite);
  return resultObj.data.updateInvite;
}

export async function updateInviteConnectorList(invite, connectorList) {
  const updatedInvite = {
    input: {
      id: invite.id,
      email: invite.email,
      group: invite.group,
      accepted: invite.accepted,
      revoked: invite.revoked,
      timestamp: invite.timestamp,
      companyId: invite.companyId,
      connectors: connectorList,
      inviteCompanyId: invite.companyId
    }
  }
  const resultObj = await runMutation(mutations.updateInvite, updatedInvite);
  return resultObj.data.updateInvite;
}