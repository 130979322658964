import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setAuthVariable
} from '../../redux/actions/authActions';

function mapStateToProps(state) {
	return {
    view: state.auth.view
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setAuthVariable: (fieldname, value) => {
			dispatch(setAuthVariable(fieldname, value))
    }
	}
}


class NewCompany extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  signInClick = () => {
    this.props.setAuthVariable('page', 'sign_in');
  }
  
  continueClick = () => {
    this.props.setAuthVariable('page', 'join_email');
  }

  onClose = () => {
    this.props.setAuthVariable('addNewCompany', false);
  }
  
  render() {
    return (
      <div className={'center'}>
        <br></br>
        <br></br>
        <br></br>
        <button onClick={this.onClose}>close</button>
        <h1>New Company</h1>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCompany);