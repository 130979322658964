import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  checkAuth,
  logout
} from '../../redux/actions/authActions';
import SignIn from '../Auth/SignIn';
import SignUp from '../Auth/SignUp';
import SignUpEmail from '../Auth/SignUpEmail';
import ConfirmCode from '../Auth/ConfirmCode';
import InviteList from '../Auth/InviteList';
import CreateCompany from '../Auth/CreateCompany';
import DashboardContainer from '../Dashboard/DashboardContainer';
import ForgotPassword from '../Auth/ForgotPassword';
// import BrandGuildline from './BrandGuildline';

function mapStateToProps(state) {
	return {
    page: state.auth.page,
    user: state.auth.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
    checkAuth: () => {
      dispatch(checkAuth())
    },
    logout: () => {
      dispatch(logout())
    }
	}
}


class App extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  async componentDidMount(){
    this.props.checkAuth();
  }
  
  render() {
    const { page } = this.props;

    // return <BrandGuildline />;

    if(page === 'sign_in') {
      return <SignIn />
    }
    if(page === 'forgot_password') {
      return <ForgotPassword />
    }
    if(page === 'sign_up') {
      return <SignUp />
    }
    if(page === 'join' || page === 'start') {
      return <SignUpEmail />
    }
    if(page === 'confirm_code') {
      return <ConfirmCode />
    }
    if(page === 'invite_list') {
      return <InviteList />
    }
    if(page === 'create_company') {
      return <CreateCompany />
    }
    // if(page === 'start') {
    //   return <Start />
    // }

    if(page === 'signed_in') {
      return <DashboardContainer />
    }

    return (
      <div className={'center'}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h2>Loading...</h2>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
