import * as a from '../constants/actions'
import * as helper from '../../helpers/helpers'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations';
import * as usersActions from './usersActions'
import {Auth} from 'aws-amplify'
import {runQuery, runMutation} from '../helpers/gqlHelper'
import * as companyHelper from '../helpers/companyHelper';

// https://api-id.execute-api.region.amazonaws.com/stage


export function setAuthVariable(fieldname, value) {
  return {type: a.SET_AUTH_VARIABLE, payload: {fieldname, value}}
}

export function setAuthVariables(object) {
  return {type: a.SET_AUTH_VARIABLES, payload: object}
}

export function resetState() {
  return {type: a.CLEAR_SESSION}
}

export function checkAuth() {
  return async function (dispatch, getState) {
    try {
      // let creds = await Auth.currentUserPoolUser();
      // console.log(creds);
      let data = await Auth.currentAuthenticatedUser({
        bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
      console.log(data);
      // console.log(data.signInUserSession.accessToken.payload['cognito:groups']);
      let user = {username: data.username, ...data.attributes}
      // console.log(user);
      dispatch(setAuthVariable('user', user))
      if (user.email_verified) {
        dispatch(checkInviteExists(user.email));
      } else {
        dispatch(setAuthVariable('page', 'sign_in'))
      }
    } catch (error) {
      dispatch(setAuthVariable('page', 'sign_in'))
      console.log(error)
    }
  }
}

export function signIn() {
  return async function (dispatch, getState) {
    try {
      const username = getState().auth.signInEmail
      const password = getState().auth.signInPassword
      if (!username || !password) {
        console.log('missing sign in data')
        return
      }
      if (!helper.validateEmail(username)) {
        dispatch(setAuthVariable('signInError', 'Invalid email format'))
        return
      }
      dispatch(setAuthVariable('signingIn', true))
      let data = await Auth.signIn({username, password})
      // console.log(data);
      let user = {username: data.username, ...data.attributes}
      // console.log(user)
      dispatch(setAuthVariable('user', user))
      if (user.email_verified) {
        dispatch(checkInviteExists(user.email));
      } else {
        dispatch(setAuthVariable('page', 'sign_up'))
        dispatch(setAuthVariable('signingIn', false))
      }
    } catch (error) {
      console.log()
      if (error.message === 'User is not confirmed.') {
        dispatch(setAuthVariable('page', 'confirm_code'))
      } else {
        dispatch(setAuthVariable('page', 'sign_in'))
        dispatch(setAuthVariable('signInError', error.message))
      }
      dispatch(setAuthVariable('signingIn', false))
      console.log(error)
    }
  }
}

export function signUp() {
  return async function (dispatch, getState) {
    try {
      const username = getState().auth.signUpEmail
      const password = getState().auth.signUpPassword
      if (!username || !password) {
        console.log('missing sign up data')
        return
      }
      if (!helper.validateEmail(username)) {
        dispatch(setAuthVariable('signUpError', 'Invalid email format'))
        return
      }

      let data = await Auth.signUp({username, password})
      // console.log(data)
      let user = {username: data.username, ...data.attributes}
      // console.log(user)
      dispatch(setAuthVariable('user', user))
      if (user.email_verified) {
        dispatch(checkInviteExists(user.email));
      } else {
        dispatch(setAuthVariable('page', 'confirm_code'))
      }
    } catch (error) {
      // dispatch(setAuthVariable('page', 'sign_in'))
      dispatch(setAuthVariable('signUpError', error.message))
      console.log(error)
    }
  }
}

export function confirmCode() {
  return async function (dispatch, getState) {

    // TODO confirm loading. It takes a while to confirm
    try {
      const code = getState().auth.confirmationCode
      let username = getState().auth.signUpEmail
      let password = getState().auth.signUpPassword
      if (!username) {
        username = getState().auth.signInEmail
        password = getState().auth.signInPassword
      }
      if (!username) {
        console.log('missing email')
        return
      }
      if (!code) {
        console.log('missing code')
        return
      }
      dispatch(setAuthVariable('confirming', true));
      let status = await Auth.confirmSignUp(username, code, {
        forceAliasCreation: true
      })
      // console.log(status)
      if (status === 'SUCCESS') {
        let data = await Auth.signIn({username, password})
        // console.log(data);
        let user = {username: data.username, ...data.attributes}
        // console.log(user)
        dispatch(setAuthVariable('user', user))
        dispatch(checkInviteExists(user.email));
        dispatch(setAuthVariable('confirming', false));
      } else {
        dispatch(setAuthVariable('confirmationCodeError', 'Incorrect code.'));
      }
    } catch (error) {
      dispatch(setAuthVariable('confirming', false));
      // dispatch(setAuthVariable('page', 'sign_in'));
      dispatch(setAuthVariable('confirmationCodeError', error.message));
      console.log(error)
    }
  }
}

export function resendEmail() {
  return async function (dispatch, getState) {
    try {
      let username = getState().auth.signUpEmail
      if (!username) {
        username = getState().auth.signInEmail
      }
      if (!username) {
        console.log('missing email')
        return
      }
      await Auth.resendSignUp(username)
      // console.log(data)
    } catch (error) {
      // dispatch(setAuthVariable('page', 'sign_in'));
      dispatch(setAuthVariable('confirmationCodeError', error.message));
      console.log(error)
    }
  }
}

export function resetPassword() {
  return async function (dispatch, getState) {
    try {
      let username = getState().auth.resetPasswordEmail
      if (!username) {
        console.log('missing email')
        return
      }
      await Auth.forgotPassword(username)
    } catch (error) {
      // dispatch(setAuthVariable('page', 'sign_in'));
      dispatch(setAuthVariable('resetPasswordError', error.message));
      console.log(error)
    }
  }
}

export function resetPasswordSubmit() {
  return async function (dispatch, getState) {
    try {
      let username = getState().auth.resetPasswordEmail
      let code = getState().auth.resetPasswordCode
      let password = getState().auth.resetPasswordNewPassword
      if (!username || !code || !password) {
        console.log('missing data')
        dispatch(setAuthVariable('resetPasswordError', 'Make sure all input is correct.'));
        return
      }
      await Auth.forgotPasswordSubmit(username, code, password);
      dispatch(setAuthVariable('page', 'sign_in'));
    } catch (error) {
      dispatch(setAuthVariable('resetPasswordError', error.message));
      console.log(error)
    }
  }
}

export function startNewCompany() {
  return async function (dispatch, getState) {
    let companyName = getState().auth.companyName;
    let user = getState().auth.user;
    dispatch(setAuthVariable('companyNameError', 'A company already exists with that name.'))
    try {
      let isUniqueName = await companyHelper.isCompanyNameUnique(companyName);
      if (!isUniqueName) {
        dispatch(setAuthVariable('companyNameError', 'A company already exists with that name.'))
        return;
      }
      const newCompany = await companyHelper.createNewCompany(companyName);
      const invite = {
        input: {
          email: user.email,
          accepted: true,
          revoked: false,
          timestamp: helper.getNowTimestamp(),
          inviteCompanyId: newCompany.id,
          companyId: newCompany.id,
          group: 'Admin',
          connectors: []
        }
      };
      // console.log(invite);
      let resultObj = await runMutation(mutations.createInvite, invite);
      let result = resultObj.data.createInvite
      // console.log(result);
      dispatch(usersActions.loadEverythingForInvite(result));
      dispatch(setAuthVariable('page', 'signed_in'))
    } catch (error) {
      console.log(error)
      // dispatch(setAuthVariable('confirmationCodeError', 'Something went wrong. Please contact support.'))
    }
  }
}

export function joinCompany(invite) {
  return async function (dispatch, getState) {
    try {
      const input = {
        input: {
          id: invite.id,
          email: invite.email,
          accepted: true,
          revoked: false,
          timestamp: helper.getNowTimestamp(),
          inviteCompanyId: invite.company.id,
        }
      }
      let resultObj = await runMutation(mutations.updateInvite, input)
      let result = resultObj.data.updateInvite
      dispatch(usersActions.loadEverythingForInvite(result));
      dispatch(setAuthVariable('page', 'signed_in'));
    } catch (error) {
      console.log(error)
    }
  }
}


export function logout() {
  return async function (dispatch, getState) {
    try {
      await Auth.signOut()
      // console.log(data)
      dispatch(resetState());
      dispatch(setAuthVariable('page', 'sign_in'))
    } catch (error) {
      console.log(error)
      dispatch(setAuthVariable('page', 'sign_in'))
    }
  }
}

export function checkInviteExists(email) {
  return async function (dispatch, getState) {
    if (!email) {
      email = getState().auth.signUpEmail;
    }
    if (!email) {
      email = getState().auth.signInEmail;
    }
    try {
      const body = {
        email: email
      }
      const invites = await runQuery(queries.invitesByEmail, body)
      // console.log('===============');
      // console.log(invites.data.invitesByEmail.items)
      // console.log('===============');
      if (invites.data.invitesByEmail.items.length === 1
        && invites.data.invitesByEmail.items[0].accepted === true
        && invites.data.invitesByEmail.items[0].revoked === false) {
        dispatch(setAuthVariable('invites', invites.data.invitesByEmail.items));
        dispatch(usersActions.loadEverythingForInvite(invites.data.invitesByEmail.items[0]));
        dispatch(setAuthVariable('page', 'signed_in'));
      } else if (invites.data.invitesByEmail.items.length > 0) {
        dispatch(setAuthVariable('invites', invites.data.invitesByEmail.items));
        dispatch(setAuthVariable('page', 'invite_list'));
      } else {
        dispatch(setAuthVariable('page', 'create_company'));
      }
      dispatch(setAuthVariable('signingIn', false))
    } catch (error) {
      console.log(error)
      dispatch(setAuthVariable('page', 'sign_in'));
      dispatch(setAuthVariable('signingIn', false))
      // dispatch(setAuthVariable('confirmationCodeError', 'Something went wrong. Please contact support.'))
    }
  }
}
