import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import * as helper from '../../helpers/helpers'
import logoBlack from '../../assets/logo_black.svg'
import {
  setAuthVariable,
  joinCompany,
  logout
} from '../../redux/actions/authActions'

function mapStateToProps(state) {
  return {
    invites: state.auth.invites,
    joinCompanyError: state.auth.joinCompanyError,
    path: state.auth.path
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
    joinCompany: invite => {
      dispatch(joinCompany(invite))
    },
    logout: () => {
      dispatch(logout())
    }
  }
}

function formatMessage(path, invites) {
  if (path === 'join' && invites.length > 1) {
    return <div>Which one would you like to join?</div>
  } else if (path === 'join' && invites.length === 1) {
    return <div>Invite found</div>
  } else if (path === 'start' && invites.length > 1) {
    return (
      <div>
        Looks like you have been invited to join these companies. Join instead?
      </div>
    )
  } else if (path === 'start' && invites.length === 1) {
    return (
      <div>
        Looks like you have been invited to join a company. Join instead?
      </div>
    )
  }
  // return 'Looks like there has been a problem. Please refresh.';
  return 'Need to overhaul this page'
}

class InviteList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  joinCompany = invite => {
    this.props.joinCompany(invite)
  }

  render() {
    const { path, invites, joinCompanyError, logout } = this.props
    const message = formatMessage(path, invites)

    return (
      <div className='center gray_background'>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className={'card margin_auto sign_in_card'}>
          <br></br>
          <img src={logoBlack} width='220' alt='logo'/>
          <br></br>
          <br></br>

          <div>Select which company to join</div>
          <br></br>
          <br></br>
          {message}
          <br></br>
          <br></br>
          {_.map(invites, row => {
            if(row.revoked) {
              return null;
            }
            return (
              <div
                key={row.email}
                className={'card pointer invite_list_item'}
                onClick={() => this.joinCompany(row)}
              >
                {row.company.name}
              </div>
            )
          })}

          {/* <br></br> */}
          <div className={'red_font'}>{joinCompanyError}</div>
          <br></br>
          <div>
            <span onClick={logout} className={'pointer'}>
              Sign out
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteList)
