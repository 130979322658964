export function managePermission(permissionGroup, section) {
  switch (section) {
    case 'changeToAdmin':
      if(permissionGroup === 'Admin') {
        return true;
      }
      return false; // default is false
    // case 'customQuestion':
    //   if(permissionGroup === 'Admin') {
    //     return true;
    //   }
    //   return false;
    default:
      return true
  }
}

export function sortAlphabeticallyByName(a, b) {
  if (a.name > b.name) {
    return 1
  } else {
    return -1
  }
}

export function findTeamName(id, teamList) {
  for (let team of teamList) {
    if (team.id === id) {
      return team.name
    }
  }
  return ''
}

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function formatDate(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000)
  var year = a.getFullYear() - 2000
  var month = a.getUTCMonth() + 1
  var date = a.getUTCDate()
  var time = month + '/' + date + '/' + year
  return time
}

// export function formatDateWithDay(UNIX_timestamp) {
//   var a = new Date(UNIX_timestamp * 1000)
//   var year = a.getFullYear() - 2000
//   var month = a.getUTCMonth() + 1
//   var date = a.getUTCDate()
//   var time = month + '/' + date + '/' + year
//   return time
// }

export function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000)
  // var a = new Date(UNIX_timestamp);
  var year = a.getFullYear() - 2000
  var month = a.getMonth() + 1
  var date = a.getDate()
  var hour = a.getHours()
  var min = a.getMinutes()
  if (min < 10) {
    min = '0' + min
  }
  var m = 'pm'
  if (hour < 12) {
    m = 'am'
  }
  if (hour >= 13) {
    hour -= 12
  } else if (hour === 0) {
    hour = 12
  }
  var time = month + '/' + date + '/' + year + ' ' + hour + ':' + min + ' ' + m
  // var time = month + '/' + date + '/' +  year;
  return time
}

export function getNextAskDates(numDays) {
  const daySeconds = 86400
  const todayMidnight = getTodayMidnightUTC();

  let askDates = []
  askDates.push(todayMidnight)
  let next = todayMidnight
  // ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  for (let i = 1; i < numDays; i++) {
    let weekend = true
    while (weekend) {
      weekend = false
      next += daySeconds
      let d = new Date(next * 1000)
      let day = d.getUTCDay()
      if (day === 0 || day === 6) {
        weekend = true
      }
    }
    askDates.push(next)
  }
  return askDates
}

export function getNowTimestamp() {
  return Math.floor(new Date() / 1000);
}

export function getTodayMidnightUTC() {
  const today = new Date()
  const month = today.getMonth()
  const day = today.getDate()
  const year = today.getFullYear()
  const todayMidnight = Date.UTC(year, month, day, 0, 0, 0, 0) / 1000
  return todayMidnight;
}

export function limitLength(str, limit) {
  // str = 'this is a really good description of what the bank does more stuff here another word'
  if(!str || str.length < limit) {
    return str;
  }
  let temp = str.slice(0, limit)
  return temp + '...';
}