import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as helper from '../../helpers/helpers'
import logoBlack from '../../assets/logo_black.svg'
import {
  signUp,
  setAuthVariable
} from '../../redux/actions/authActions'

function mapStateToProps(state) {
  return {
    signUpError: state.auth.signUpError,
    signUpEmail: state.auth.signUpEmail,
    signUpPassword: state.auth.signUpPassword
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
    signUp: () => {
      dispatch(signUp())
    }
  }
}

class SignUpEmail extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onSignUp = event => {
    this.props.signUp()
    event.preventDefault()
  }

  onEmailChange = event => {
    this.props.setAuthVariable('signUpError', '')
    this.props.setAuthVariable('signUpEmail', event.target.value)
  }

  onPasswordChange = event => {
    this.props.setAuthVariable('signUpError', '')
    this.props.setAuthVariable('signUpPassword', event.target.value)
  }

  onBackClick = () => {
    this.props.setAuthVariable('page', 'sign_up')
  }

  signInClick = () => {
    this.props.setAuthVariable('page', 'sign_in')
  }

  render() {
    const { signUpError, signUpEmail, signUpPassword } = this.props
    let emailValid = helper.validateEmail(signUpEmail)
    let disabled = true
    if (signUpEmail && emailValid && signUpPassword) {
      disabled = false
    }
    return (
      <div className='center gray_background'>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className={'card margin_auto sign_in_card'}>
          <br></br>
          <img src={logoBlack} width='220' alt='logo'/>
          <br></br>
          <br></br>

          <div>Sign up</div>
          <br></br>
          <br></br>
          <form onSubmit={this.onSignUp}>
            <input
              value={signUpEmail}
              className={
                signUpEmail
                  ? emailValid
                    ? 'active_input max_width'
                    : 'error_input max_width'
                  : 'max_width'
              }
              type='email'
              placeholder='Email'
              name='email'
              onChange={this.onEmailChange}
            />
            <br></br>
            <input
              className={'margin_top_5'}
              value={signUpPassword}
              type='password'
              placeholder='Password'
              name='password'
              onChange={this.onPasswordChange}
            />
            <br></br>
            <br></br>
            <button type='submit' className={'green_button'} disabled={disabled}>
              create user
            </button>
          </form>
          <br></br>
          <div className={'red_font'}>{signUpError}</div>
          <br></br>
          <div>
            Already have an account?{' '}
            <span onClick={this.signInClick} className={'link'}>
              Sign in
            </span>
          </div>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpEmail)
