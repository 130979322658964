/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:693718fc-b99b-4af5-8ec6-4b471025d5d2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_1qT9OM5LC",
    "aws_user_pools_web_client_id": "1jv7ju1m0ghg0i4n8b2b3qp92a",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://7j3uhdl2qjho5f454bfn33llpm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "restapi",
            "endpoint": "https://0q8nip09ba.execute-api.us-west-2.amazonaws.com/pollbridge",
            "region": "us-west-2"
        }
    ],
    "aws_content_delivery_bucket": "pollbridgedev-pollbridge",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://pollbridgedev-pollbridge.s3-website-us-west-2.amazonaws.com"
};


export default awsmobile;
