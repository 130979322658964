import * as helper from '../../helpers/helpers'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { runQuery, runMutation } from './gqlHelper'

export async function getCompanyById(companyId) {
  const input = {
    id: companyId
  }
  const resultObj = await runQuery(queries.getCompany, input)
  return resultObj.data.getCompany
}

export async function isCompanyNameUnique(name) {
  let input = {
    filter: {
      name: {
        eq: name
      }
    }
  }
  const resultObj = await runQuery(queries.listCompanys, input)
  const result = resultObj.data.listCompanys
  if (result.items.length > 0) {
    return false
  }
  return true
}

export async function createNewCompany(name) {
  const input = {
    input: {
      name: name,
      start: helper.getNowTimestamp()
    }
  }
  const newCompanyResult = await runMutation(mutations.createCompany, input)
  return newCompanyResult.data.createCompany
}
