import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import InviteUserManually from './InviteUserManually'
import * as helpers from '../../helpers/helpers'

import {
  sendInvite,
  revokeInvite,
  updateInviteGroup
} from '../../redux/actions/usersActions'

function mapStateToProps(state) {
  return {
    inviteList: state.users.inviteList,
    permissionGroup: state.auth.permissionGroup
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendInvite: email => {
      dispatch(sendInvite(email))
    },
    revokeInvite: invite => {
      dispatch(revokeInvite(invite))
    },
    updateInviteGroup: (invite, group) => {
      dispatch(updateInviteGroup(invite, group))
    }
  }
}

function formatUserList(answerList, inviteList) {
  let users = {}
  for (let invite of inviteList) {
    if (invite.email) {
      invite.answer = false
      invite.invite_sent = true
      users[invite.email] = invite
    }
  }
  for (let answer of answerList) {
    if (answer.email && users[answer.email]) {
      users[answer.email].answer = true
    } else if (answer.email) {
      users[answer.email] = {
        answer: true,
        invite_sent: false
      }
    }
  }
  return users
}

function getInviteStatus(row) {
  if (row.revoked) {
    return 'Revoked'
  } else if (row.accepted) {
    return 'Accepted'
  } else if (row.invite_sent) {
    return 'Sent'
  } else {
    return 'Send'
  }
}

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUser: {}
    }
  }

  onSendInvite = row => {
    const { sendInvite } = this.props
    sendInvite(row.email)
  }
  onRevokeInvite = row => {
    this.props.revokeInvite(row)
  }

  onUpdateInviteGroup = (row, group) => {
    if (row.group !== group) {
      this.props.updateInviteGroup(row, group)
    }
  }

  onRowClick = (key, user) => {
    const { currentUser } = this.state
    if (currentUser.id === user.id) {
      this.setState({
        currentUser: {}
      })
    } else {
      this.setState({
        currentUser: user
      })
    }
  }

  render() {
    const { inviteList, permissionGroup } = this.props
    const { currentUser } = this.state
    const users = formatUserList([], inviteList)
    const usersKeys = Object.keys(users)

    return (
      <div>
        <br></br>
        <br></br>
        <h2>Users</h2>
        <br></br>
        <div>
          An invite gives login access for the company account. To give
          connector level access, click on the connector name in the sidebar and
          go to the Share tab.
        </div>
        <br></br>
        <div>Invite users manually</div>
        <InviteUserManually />
        <br></br>
        <br></br>
        <table style={{ width: '100%' }}>
          <thead>
            <tr className={'table_header_row'}>
              <th className={'table_header_item'}>Email</th>
              <th className={'table_header_item'}>Permission level</th>
            </tr>
          </thead>

          {_.map(usersKeys, key => {
            const status = getInviteStatus(users[key])
            // console.log(users[key]);
            // console.log(users[key].group);
            return (
              <tbody key={key} className={'table_row'}>
                <tr
                  onClick={() => this.onRowClick(key, users[key])}
                  className={'pointer'}
                >
                  <td className={'table_item'}>
                    <div>{key}</div>
                  </td>
                  <td className={'table_item'}>
                    <div>{users[key].group}</div>
                  </td>
                </tr>
                {currentUser.id === users[key].id ? (
                  <tr>
                    <td className={'table_item'} colSpan={'2'}>
                      <div>
                        {status === 'send' ? (
                          <button onClick={() => this.onSendInvite(users[key])}>
                            invite
                          </button>
                        ) : (
                          <span>Invite status: {status}</span>
                        )}
                        <br></br>
                        {helpers.managePermission(
                          permissionGroup,
                          'changeToAdmin'
                        ) && (
                          <div className={'right'}>
                            {!users[key].revoked ? (
                              <span>
                                <button
                                  className={'outline_red'}
                                  onClick={() =>
                                    this.onRevokeInvite(users[key])
                                  }
                                >
                                  Revoke Invite
                                </button>
                                {users[key].group !== 'Member' ? (
                                  <button
                                    className={'outline margin_left_10'}
                                    onClick={() =>
                                      this.onUpdateInviteGroup(
                                        users[key],
                                        'Member'
                                      )
                                    }
                                  >
                                    Make Member
                                  </button>
                                ) : null}
                                {users[key].group !== 'Admin' ? (
                                  <button
                                    className={'outline margin_left_10'}
                                    onClick={() =>
                                      this.onUpdateInviteGroup(
                                        users[key],
                                        'Admin'
                                      )
                                    }
                                  >
                                    Make Admin
                                  </button>
                                ) : null}
                              </span>
                            ) : (
                              <button
                                  className={'outline'}
                                  onClick={() =>
                                    this.onSendInvite(users[key])
                                  }
                                >
                                  Send Invite
                                </button>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            )
          })}
        </table>
        <br></br>
        {/* <div>Users that have responded to the answers</div>
        {_.map(users, row => {
          const status = getInviteStatus(row);
          return (
            <div key={row.email} className={'user_row'}>
              {row.email}{' '}
              {status === 'send' ? (
                <button onClick={() => this.sendInvite(row)}>invite</button>
                ) : (
                <span>{status}</span>
              )}
            </div>
          )
        })} */}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users)
