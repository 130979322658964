import React, { Component } from 'react'
import { connect } from 'react-redux'
// import * as helper from '../../helpers/helpers'
import logoBlack from '../../assets/logo_black.svg'
import {
  signUp,
  setAuthVariable,
  confirmCode,
  resendEmail
} from '../../redux/actions/authActions'

function mapStateToProps(state) {
  return {
    confirmationCode: state.auth.confirmationCode,
    confirmationCodeError: state.auth.confirmationCodeError,
    confirming: state.auth.confirming,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
    signUp: () => {
      dispatch(signUp())
    },
    confirmCode: () => {
      dispatch(confirmCode())
    },
    resendEmail: () => {
      dispatch(resendEmail())
    }
  }
}

class ConfirmCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resent: false
    }
  }

  onSubmit = event => {
    this.props.confirmCode();
    event.preventDefault()
  }

  onCodeChange = event => {
    this.props.setAuthVariable('confirmationCodeError', '')
    this.props.setAuthVariable('confirmationCode', event.target.value)
  }

  onBackClick = () => {
    this.props.setAuthVariable('page', 'sign_up')
  }

  resendEmailClick = () => {
    this.setState({
      resent: true
    })
    this.props.resendEmail();
    // this.props.setAuthVariable('page', 'sign_in')
  }

  render() {
    const { confirmationCode, confirmationCodeError, confirming } = this.props
    const { resent } = this.state
    // let emailValid = helper.validateEmail(signUpEmail);
    let disabled = true
    if (confirmationCode) {
      disabled = false
    }
    return (
      <div className='center gray_background'>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className={'card margin_auto sign_in_card'}>
          <br></br>
          <img src={logoBlack} width='220' alt='logo'/>
          <br></br>
          <br></br>

          <div>Confirmation code</div>
          <br></br>
          <div>Check email for verification code.</div>
          <br></br>
          <form onSubmit={this.onSubmit}>
          <input
            value={confirmationCode}
            type='text'
            placeholder='Confirmation Code'
            name='confirmation_code'
            onChange={this.onCodeChange}
          />
          <br></br>
          <br></br>
          <button type='submit' className={'green_button'} disabled={disabled}>
            {confirming ? 'Loading...' : 'confirm'}
          </button>
        </form>
        <br></br>
        <div className={'red_font'}>{confirmationCodeError}</div>
        <br></br>
        {resent ? (
          <div>Email resent</div>
        ) : (
          <div>
            Didn't recieved email?{' '}
            <span onClick={this.resendEmailClick} className={'link'}>
              Resend email
            </span>
          </div>
        )}
        <br></br>
        <br></br>
        </div>
      
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmCode)
