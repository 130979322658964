import React, { Component } from 'react'
import { connect } from 'react-redux'
import Sidebar from './Sidebar'
import NewTeam from './NewTeam'
import NewCompany from './NewCompany'
import NewConnector from './NewConnector'
import DashboardTabContent from './DashboardTabContent'
import Account from './Account'
import Banner from './Banner'
import { setAuthVariable } from '../../redux/actions/authActions'

function mapStateToProps(state) {
  return {
    // view: state.auth.view,
    // team: state.auth.team,
    // connector: state.auth.connector,
    addNewTeam: state.auth.addNewTeam,
    addNewCompany: state.auth.addNewCompany,
    addNewConnector: state.auth.addNewConnector,
    viewAccountPage: state.auth.viewAccountPage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    }
  }
}

class DashboardContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getCurrentContent = (viewAccountPage, addNewConnector, addNewTeam) => {
    if(viewAccountPage) {
      return <Account />
    }else if(addNewConnector) {
      return <NewConnector />
    }else if(addNewTeam) {
      return <NewTeam />
    }
    return <DashboardTabContent />
  }

  onViewChange = (view) => {
		this.props.setAuthVariable('view', view);
  }

  render() {
    const {
      addNewTeam,
      addNewCompany,
      addNewConnector,
      viewAccountPage,
    } = this.props

    if (addNewCompany) {
      return <NewCompany />
    }
    // if (addNewTeam) {
    //   return <NewTeam />
    // }
    // if (addNewConnector) {
    //   return <NewConnector />
    // }

    return (
      <div>
        {/* <Header company={company} user={user} /> */}
        <Banner />
        <div className={'body_container'}>
          <div className={'sidebar_container'}>
            <Sidebar />
          </div>
          <div className={'dashboard_container'}>
            {/* <div>company: {company.id}</div>
            <div>connector: {connector.id}</div>
            <div>team: {team.id}</div> */}
            <br></br>
            <br></br>
            <div>{this.getCurrentContent(viewAccountPage, addNewConnector, addNewTeam)}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer)
