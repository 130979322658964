import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dropdown from 'react-dropdown'
// import { MdAdd, MdRemove } from 'react-icons/md'
import { setAuthVariable } from '../../redux/actions/authActions'
import * as helpers from '../../helpers/helpers'

import 'react-dropdown/style.css'

function mapStateToProps(state) {
  return {
    view: state.auth.view,
    questionList: state.users.questionList,
    bankList: state.users.bankList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAuthVariable: (fieldname, value) => {
      dispatch(setAuthVariable(fieldname, value))
    },
  }
}

// const options = ['one', 'two', 'three']
const options = [
  {
    value: [
      'Strongly Agree',
      'Moderately Agree',
      'Slightly Agree',
      'Neutral',
      'Slightly Disagree',
      'Moderately Disagree',
      'Strongly Disagree'
    ],
    label: '7 Point Likert Scale'
    // helpText: '?'
  },
  {
    value: [
      'Strongly Agree',
      'Slightly Agree',
      'Neutral',
      'Slightly Disagree',
      'Strongly Disagree'
    ],
    label: '5 Point Likert Scale'
  },
  {
    value: ['True', 'False'],
    label: 'True/False'
  }
]

function formatQuestionDetails(question, questionList) {
  for (let q of questionList) {
    if (q.question && q.question.id === question.id) {
      // console.log(q);
      return q
    }
  }
  return {}
}

function formatQuestionBank(question, bankList) {
  for (let b of bankList) {
    if (b.id === question.questionBankId) {
      return b
    }
  }
  return {}
}

class CustomQuestionLocal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      positiveFirst: true,
      dropdownValue: options[0],
      showAddQuestion: false
    }
  }

  statementChange = event => {
    let text = event.target.value
    if (text.length > 100) {
      text = text.substring(0, 100)
    }
    this.setState({
      text
    })
  }

  makePositive = bool => {
    this.setState({
      positiveFirst: bool
    })
  }

  toggleOptions = () => {
    let { positiveFirst } = this.state
    if (positiveFirst) {
      positiveFirst = false
    } else {
      positiveFirst = true
    }
    this.setState({
      positiveFirst
    })
  }

  dropdownChange = option => {
    this.setState({
      dropdownValue: option
    })
  }

  onCancel = () => {
    if(!this.props.question.text) {
      this.onCloseClick()
    }
    this.setState({
      showAddQuestion: false,
      text: '',
      positiveFirst: true,
      dropdownValue: options[0]
    })
  }

  onShowAdd = () => {
    this.setState({
      showAddQuestion: true
    })
  }

  submitQuestion = () => {
    const { text, positiveFirst, dropdownValue } = this.state
    let options = _.cloneDeep(dropdownValue.value)
    if (!positiveFirst) {
      options = options.reverse()
    }



    // TODO figure out when to use onEditQuestion

    this.props.onNewQuestion({
      text,
      options,
      question: this.props.question,
    })
    this.onCancel()
  }

  onEditClick = question => {
    let dropdownValue = options[0];
    let positiveFirst = true;
    for(let option of options) {
      let inter = _.intersection(option.value, question.options);
      if(inter.length === question.options.length && question.options.length === option.value.length) {
        dropdownValue = option;
        if(question.options[0] !== option.value[0]) {
          positiveFirst = false;
        }
      }
    }
    this.setState({
      showAddQuestion: true,
      text: question.text,
      options: question.options,
      dropdownValue,
      positiveFirst
    })
  }
  onCloseClick = () => {
    this.props.onCloseClick()
  }

  render() {
    const {
      text,
      positiveFirst,
      dropdownValue,
      showAddQuestion
    } = this.state

    const { question, questionList, bankList } = this.props
    // console.log(question)
    // console.log(question.text)
    const questionDetails = formatQuestionDetails(question, questionList)
    const bank = formatQuestionBank(question, bankList)

    let editMode = false
    if (!question.text || showAddQuestion) {
      editMode = true
    }

    let previewStatement = 'Statement goes here'
    if (!editMode && question.text) {
      previewStatement = question.text
    } else if (text) {
      previewStatement = text
    }

    let previewOptions = _.cloneDeep(dropdownValue.value)
    if (!positiveFirst) {
      previewOptions = previewOptions.reverse()
    }
    if (!editMode && question.options) {
      previewOptions = question.options
    }

    return (
      <div>
        <div className={'flex_wrap_row'}>
          <div style={{ width: 350, marginRight: 20, display: 'inline' }}>
            {!editMode ? (
              <div
                className={'flex_space_between_column'}
                style={{ height: '100%' }}
              >
                <div>
                  <h3>Question details</h3>
                  <br></br>
                  <div>Statement: {question.text}</div>
                  <div>Category: {question.category}</div>
                  <div>
                    Next ask date:{' '}
                    {questionDetails.nextAskDate >=
                    helpers.getTodayMidnightUTC()
                      ? helpers.formatDate(questionDetails.nextAskDate)
                      : 'Not currently scheduled'}
                  </div>
                  <div>Active: {questionDetails.active ? 'Yes' : 'No'}</div>
                  <div>Question bank: {bank.name}</div>
                  {bank.public ? <div>This bank is public</div> : null}

                  <br></br>
                  <br></br>
                  <br></br>
                </div>
                <div>
                  {!editMode && (
                    <div className={'left'}>
                      {!bank.public && (
                        <button
                          onClick={() => this.onEditClick(question)}
                          className={'outline'}
                        >
                          edit
                        </button>
                      )}
                      <button
                        onClick={this.onCloseClick}
                        className={'outline margin_left_10'}
                      >
                        close
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={'flex_space_between_column'}
                style={{ height: '100%' }}
              >
                <div>
                  <div>Please add custom question statement.</div>
                  <div className={'spacer_10'}></div>
                  <input
                    value={text}
                    className='custom_question_input'
                    onChange={this.statementChange}
                    placeholder={'Question statement (limit 100 characters)'}
                  />

                  <br></br>
                  <br></br>

                  <div>Please select answer type.</div>
                  <div className={'spacer_10'}></div>
                  <Dropdown
                    options={options}
                    onChange={this.dropdownChange}
                    value={dropdownValue}
                    placeholder='Select an option'
                    // className='custom_question_option_select'
                    // controlClassName='myControlClassName'
                    placeholderClassName='custom_question_option_placeholder'
                    // menuClassName='myMenuClassName'
                    arrowClassName='custom_question_option_arrow'
                    // arrowClosed={<span className="arrow-closed" />}
                    // arrowOpen={<span className="arrow-open" />}
                  />
                  <div className={'spacer_10'}></div>
                  <div>
                    NOTE: Make sure the top answer option is the most positive
                    answer.
                  </div>
                  <div className={'spacer_10'}></div>
                  <button className={'outline'} onClick={this.toggleOptions}>
                    Reverse options
                  </button>
                  <div className={'spacer_10'}></div>
                  <div className={'spacer_10'}></div>
                  <div className={'spacer_10'}></div>
                  <div className={'spacer_10'}></div>
                  <div className={'spacer_10'}></div>
                  <div className={'spacer_10'}></div>
                </div>
                <div>

                  <div className={'left'}>
                    <button
                      onClick={this.submitQuestion}
                      className={'green_button margin_right_10'}
                      disabled={!text}
                    >
                      save
                    </button>
                    <button onClick={this.onCancel} className={'outline'}>
                      cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* ////////////////////////////////// */}

          <div style={{ width: 350 }}>
            <div>Question Preview</div>
            <div className={'spacer_10'}></div>
            <div className={'card'}>
              <div className={'spacer_10'}></div>
              <div className={'center'} style={{ fontSize: '24px' }}>
                {previewStatement}
              </div>
              <div className={'spacer_10'}></div>
              <div className={'spacer_10'}></div>
              <div className={'radio_group'}>
                {_.map(previewOptions, row => {
                  return (
                    <span key={row}>
                      {/* <div>{row}</div> */}
                      <input
                        type='radio'
                        id={row}
                        name='gender'
                        value={row}
                        className={'radio_option'}
                      />
                      <label htmlFor={row}>{row}</label>
                      <br></br>
                    </span>
                  )
                })}
              </div>
              <div className={'spacer_10'}></div>
              <div className={'spacer_10'}></div>
              <div className={'spacer_10'}></div>
              <div className={'center'}>
                <button className={'blue_button'}>submit</button>
              </div>
              <div className={'spacer_10'}></div>
              <div className={'spacer_10'}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomQuestionLocal)
