import * as a from '../constants/actions';

const INITIAL_STATE = {
  page: 'loading',
  path: '',
  view: 'dashboard',
  team: {},
  connector: {},
  company: {},
  invites: [],
  user: {},
  currentInvite: {},
  permissionGroup: 'Member',

  addNewTeam: false,
  addNewCompany: false,
  addNewConnector: false,
  viewAccountPage: false,

  connectorsLoaded: false,

  test: 'this is working',

  signInEmail: '',
  signInPassword: '',
  signInError: '',
  signingIn: false,

  signUpEmail: '',
  signUpPassword: '',
  signUpError: '',

  confirmationCode: '',
  confirmationCodeError: '',
  confirming: false,

  resetPasswordError: '',
  resetPasswordEmail: '',
  resetPasswordCode: '',
  resetPasswordNewPassword: '',
  resetPasswordConfirmNewPassword: '',

  companyName: '',
  companyNameError: '',
  companyNameLoading: false,

  loadingDashboard: false,
  loadingQuestions: false,
  loadingAnswers: false,
  loadingTeamMembers: false, 
  loadingBankQuestions: false,

  inviteListError: '', // do i need this?

  uninstallingBank: false,
  downloadingBank: false,
  submittingNewConnector: false,
  sendingInvite: false,

}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case a.CLEAR_SESSION: {
			return { ...INITIAL_STATE }
		}
		case a.SET_AUTH_VARIABLE: {
			if(action.payload.fieldname) {
				state[action.payload.fieldname] = action.payload.value;
			}
			return { ...state }
    }
    case a.SET_AUTH_VARIABLES: {
			return { ...state, ...action.payload }
		}
		default: {
			return { ...state }
		}
	}
}

