import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as helper from '../../helpers/helpers'
import { GoGrabber } from 'react-icons/go'
import CustomQuestionLocal from './CustomQuestionLocal'
import Modal from 'react-modal'

import {
  updateScheduleOrder,
  updateQuestion
} from '../../redux/actions/usersActions'

Modal.setAppElement('#root')

const SCHEDULE_SIZE = 15
const DATES = helper.getNextAskDates(SCHEDULE_SIZE)

function mapStateToProps(state) {
  return {
    team: state.auth.team,
    teamMembers: state.users.teamMembers,

    questionList: state.users.questionList,
    permissionGroup: state.auth.permissionGroup,
    loadingQuestions: state.auth.loadingQuestions,
    loadingAnswers: state.auth.loadingAnswers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateScheduleOrder: items => {
      dispatch(updateScheduleOrder(items))
    },
    updateQuestion: question => {
      dispatch(updateQuestion(question))
    }
  }
}

function formatQuestionListPositions(questions, dates) {
  // console.log(queue)
  // console.log(dates);
  for (let q of questions) {
    // console.log(q.nextAskDate)
    for (let i = 0; i < dates.length; i++) {
      // console.log(dates[i], i);
      if (q.nextAskDate === dates[i]) {
        q.position = i + 1
      }
    }
  }
  return questions
}

function shouldShowCard(items) {
  for (let item of items) {
    if (!item.position) {
      return true
    }
  }
  return false
}

class Schedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      currentQuestion: {},
      hasChanged: false,
      items: formatQuestionListPositions(props.questionList, DATES)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.questionList !== this.props.questionList) {
      let itemsTemp = formatQuestionListPositions(nextProps.questionList, DATES)
      this.setState({ items: itemsTemp })
    }
  }

  allowDrop = event => {
    event.preventDefault()
  }

  drag = event => {
    event.dataTransfer.setData('text', event.target.id)
  }

  drop = (event, pos) => {
    event.preventDefault()
    var id = event.dataTransfer.getData('text')
    let newItems = _.cloneDeep(this.state.items)
    for (let i of newItems) {
      if (i.position === pos) {
        i.position = null
      }
      if (i.id === id) {
        i.position = pos
      }
    }
    this.setState({
      hasChanged: true,
      items: newItems
    })
  }

  getBoxDate = (i, datesArr) => {
    return helper.formatDate(datesArr[i - 1])
    // return i
  }

  getCurrent = p => {
    for (let item of this.state.items) {
      if (item.position === p) {
        return (
          <div
            // key={item.id}
            id={item.id}
            draggable='true'
            onDragStart={this.drag}
            onClick={() => this.viewQuestionDetails(item.question)}
            className={'single_schedule_card_black'}
            // style={{ height: 142, width: '100%', border: 'solid black 1px', backgroundColor: 'blue' }}
          >
            {item.question.text}
          </div>
        )
      }
    }
    return null
  }

  onSaveSchedule = dates => {
    // console.log(this.state.items);
    // console.log(dates);
    const todayMidnight = helper.getTodayMidnightUTC()
    const itemsToUpdate = []
    for (let item of this.state.items) {
      if (item.position) {
        // check if nextAskDate is different than position,
        // update if it is, do nothing if not
        if (item.nextAskDate !== dates[item.position - 1]) {
          // console.log('update with new nextAskDate')
          item.nextAskDate = dates[item.position - 1]
          itemsToUpdate.push(item)
          // }else{
          //   console.log('we are good')
        }
      }
      if (!item.position && item.nextAskDate >= todayMidnight) {
        // make nextAskDate null and update
        // console.log('make next ask date null and update')
        item.nextAskDate = null
        itemsToUpdate.push(item)
      }
    }
    this.props.updateScheduleOrder(itemsToUpdate)
    this.setState({
      hasChanged: false
    })
  }

  viewQuestionDetails = question => {
    this.setState({
      modalIsOpen: true,
      currentQuestion: question
    })
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      currentQuestion: {}
    })
  }

  onNewQuestion = questionObj => {
    if (questionObj.question && questionObj.question.id) {
      let temp = _.cloneDeep(questionObj.question)
      temp.text = questionObj.text
      temp.options = questionObj.options
      this.props.updateQuestion(temp)
    }
    this.closeModal()
  }

  render() {
    const { questionList } = this.props
    const { items, hasChanged, modalIsOpen, currentQuestion } = this.state

    items.sort(function(a, b) {
      if (a.active && b.active) {
        return 0;
      }else if(a.active) {
        return -1
      } else {
        return 1
      }
    })
    const containers = []
    for (let i = 1; i <= SCHEDULE_SIZE; i++) {
      containers.push(
        <div
          key={i}
          onDrop={event => this.drop(event, i)}
          onDragOver={this.allowDrop}
          style={{ width: '20%' }}
        >
          <div
            className={'single_schedule_card_box'}
            // style={{
            //   height: 157,
            //   border: 'solid #ddd 1px',
            //   // border: 'solid black 1px',
            //   // backgroundColor: 'pink',
            //   padding: 5,
            // }}
          >
            <div className={'schedule_box_date'}>
              {this.getBoxDate(i, DATES)}
            </div>
            <div className={'schedule_box_body'}>{this.getCurrent(i)}</div>
          </div>
        </div>
      )
    }

    // let message = null
    // if (loadingAnswers) {
    //   message = 'Loading...'
    // } else if (loadingQuestions) {
    //   message = 'Loading...'
    // }

    let showCardBottom = shouldShowCard(items)

    return (
      <div>
        <br></br>
        <br></br>
        {/* <br></br> */}
        {questionList.length === 0 ? (
          <div>
            No questions currently attached to group. To add questions, go to
            the Marketplace tab.
          </div>
        ) : (
          <div>
            {hasChanged ? (
              <span>
                <button
                  className={'green_button'}
                  onClick={() => this.onSaveSchedule(DATES)}
                >
                  save schedule
                </button>
                <br></br>
                <br></br>
              </span>
            ) : null}
            <div className={'flex_wrap_row single_schedule_card_container'}>
              {containers}
            </div>
            <br></br>
            <div>
              Note: The schedule will automatically build itself when there are
              fewer than 5 questions scheduled. You don't need to build the
              schedule unless you would like to control what questions get asked
              on what days.
            </div>
            {/* <div className={'flex_wrap_row card'}>{containers}</div> */}
            <br></br>
            {showCardBottom ? (
              <div
                onDrop={event => this.drop(event, null)}
                onDragOver={this.allowDrop}
                // style={{ padding: 20, border: 'solid black 1px' }}
                className={'card'}
              >
                {items.map((item, index) => {
                  if (item.position) {
                    return null
                  }
                  return (
                    <div
                      key={item.id}
                      id={item.id}
                      draggable={item.active}
                      onDragStart={this.drag}
                      onClick={() => this.viewQuestionDetails(item.question)}
                      // style={{ height: 50, border: 'solid black 1px' }}
                      className={`single_schedule_row flex_space_between align_center ${
                        item.active ? 'grabber' : 'pointer'
                      }`}
                    >
                      <span>
                        {item.active ? (
                          <div className={'flex_wrap_row align_center'}>
                            <GoGrabber style={{ fontSize: '25px' }} />
                            &nbsp; &nbsp;{item.question.text}
                          </div>
                        ) : (
                          <span>{item.question.text}</span>
                        )}
                      </span>
                      <div>{item.active ? 'Active' : 'Inactive'}</div>
                    </div>
                  )
                })}
              </div>
            ) : null}
          </div>
        )}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          className={'modal_content'}
          overlayClassName={'modal_overlay'}
        >
          <CustomQuestionLocal
            onNewQuestion={this.onNewQuestion}
            question={currentQuestion}
            onCloseClick={this.closeModal}
          />
        </Modal>

        <br></br>
        <br></br>
        <br></br>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedule)
