import _ from 'lodash'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setAuthVariable
} from '../../redux/actions/authActions';
import * as helpers from '../../helpers/helpers'

function mapStateToProps(state) {
	return {
    view: state.auth.view,
    questionList: state.users.questionList
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setAuthVariable: (fieldname, value) => {
			dispatch(setAuthVariable(fieldname, value))
    },
    
	}
}

function orderQuestions(questionList, todayMidnight) {
  
  return questionList.sort((a, b) => {
    if(a.nextAskDate < b.nextAskDate && a.nextAskDate > todayMidnight) {
      return -1;
    }else{
      return 1;
    }
  })
}

class Queue extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  signInClick = () => {
    this.props.setAuthVariable('page', 'sign_in');
  }
  
  continueClick = () => {
    this.props.setAuthVariable('page', 'join_email');
  }

  
  render() {
    const { questionList } = this.props;
    const todayMidnight = helpers.getTodayMidnightUTC();
    let questions = orderQuestions(questionList, todayMidnight);
    return (
      <div>
        <h1>Queue</h1>
        <br></br>
        {_.map(questions, row => {
          // const status = getInviteStatus(row);
          return (
            <div key={row.id} className={'user_row'}>
              <div>{row.question.category}</div>
              <div>{row.question.text}</div>
              {parseInt(row.nextAskDate) >= todayMidnight ? (
                <span>{helpers.formatDate(parseInt(row.nextAskDate))}</span>
                ) : (
                <span>no date set yet</span>
              )}
            </div>
          )
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Queue);









// {
//   "answers": [
//       {
//       "email": "nathanradmall+fake3@gmail.com",
//       "timestamp": 1234, 
//       "answer": "Neutral",
//       "extraText": "",
//       "weight": 3,
//       "answerTeamQuestionId": "0a93c48c-a702-40a0-a424-60f0314a1a32"
//       }
//   ]
// }



// "Strongly Agree",
// "Slightly Agree",
// "Neutral",
// "Slightly Disagree",
// "Strongly Disagree",

// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Slightly Disagree",
//   "extraText": "",
//   "weight": 4,
//   "answerTeamQuestionId": "0a93c48c-a702-40a0-a424-60f0314a1a32"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Slightly Disagree",
//   "extraText": "",
//   "weight": 4,
//   "answerTeamQuestionId": "b8e86f19-ef56-4302-bb96-80e1423df91a"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Slightly Agree",
//   "extraText": "",
//   "weight": 2,
//   "answerTeamQuestionId": "5ae0181d-6ca8-4f87-90ad-2681d4ec7eda"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Strongly Disagree",
//   "extraText": "",
//   "weight": 5,
//   "answerTeamQuestionId": "da33da22-eab4-48d5-9a6e-211faa43a6c3"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Neutral",
//   "extraText": "",
//   "weight": 3,
//   "answerTeamQuestionId": "0d63ec84-234c-4b9c-be41-0d8624d958e2"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Strongly Agree",
//   "extraText": "",
//   "weight": 1,
//   "answerTeamQuestionId": "7775bd8c-1598-429c-8575-2988818b65f1"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Strongly Agree",
//   "extraText": "",
//   "weight": 1,
//   "answerTeamQuestionId": "a166ca66-ef60-4d76-b86a-c5ae0394d4dc"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Slightly Disagree",
//   "extraText": "",
//   "weight": 4,
//   "answerTeamQuestionId": "300acfcf-306c-4963-b335-a36f9de22c95"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Slightly Disagree",
//   "extraText": "",
//   "weight": 4,
//   "answerTeamQuestionId": "6d12ec13-94d4-47d1-ab41-5590188a5322"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Slightly Agree",
//   "extraText": "",
//   "weight": 2,
//   "answerTeamQuestionId": "932be4b4-7563-4fb3-8861-370d1772dc61"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Slightly Agree",
//   "extraText": "",
//   "weight": 2,
//   "answerTeamQuestionId": "3f179adf-6cf0-4f6d-b32e-547ef9ed6273"
// },
// {
//   "email": "nathanradmall+fake4@gmail.com",
//   "timestamp": 1234,
//   "answer": "Slightly Agree",
//   "extraText": "",
//   "weight": 2,
//   "answerTeamQuestionId": "3811225e-eea8-4332-9039-677ad9d185a7"
// }