import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as helper from '../../helpers/helpers';
import logoBlack from '../../assets/logo_black.svg'
import {
  signIn,
  setAuthVariable
} from '../../redux/actions/authActions';
// import people from '../../assets/people_v2.svg';
import lady_chair_laptop from '../../assets/lady_chair_laptop.svg';

function mapStateToProps(state) {
	return {
    signInEmail: state.auth.signInEmail,
    signInPassword: state.auth.signInPassword,
    signInError: state.auth.signInError,
    signingIn: state.auth.signingIn
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setAuthVariable: (fieldname, value) => {
			dispatch(setAuthVariable(fieldname, value))
    },
    signIn: () => {
      dispatch(signIn())
    }
	}
}


class SignIn extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  onSignIn = (event) => {
		this.props.signIn();
		event.preventDefault();
	}

	onEmailChange = (event) => {
    this.props.setAuthVariable('signInError', '');
		this.props.setAuthVariable('signInEmail', event.target.value);
	}

	onPasswordChange = (event) => {
		this.props.setAuthVariable('signInPassword', event.target.value);
  }
  
  signUpClick = () => {
    this.props.setAuthVariable('page', 'sign_up');
  }

  forgotPasswordClick = () => {
    this.props.setAuthVariable('page', 'forgot_password');
  }
  
  render() {
    const { signInEmail, signInPassword, signInError, signingIn } = this.props;
    let emailValid = helper.validateEmail(signInEmail);
		let disabled = true;
		// if (signInEmail && emailValid && signInPassword) {
    if (signInEmail && signInPassword) {
			disabled = false;
		}
    return (
      <div className='center gray_background'>
        {/* <Header /> */}
        
        {/* <div className={'test_1'}></div>
        <div className={'test_2'}></div>
        <div className={'test_3'}></div>
        <div className={'test_4'}></div>
        <div className={'test_6'}></div>
        <div className={'test_7'}></div>
        <div className={'test_8'}></div>
        <div className={'test_9'}></div> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className={'card margin_auto sign_in_card'}>
          <br></br>
          <img src={logoBlack} width='220' alt='logo'/>
          <br></br>
          {/* <img src={logoBlack} width='200' alt='logo' style={{ backgroundColor: 'red'}}/> */}
          {/* <h1 className={'logo_font'}>PollFeedback</h1> */}
          {/* <div className={'logo'}>
            <span className={'poll_dark'}>Poll Bridge</span>
            <div style={{ width: '75px', height: '12px', margin: 'auto', borderLeft: 'solid black 5px', borderRight: 'solid black 5px' }}>  
            </div>
          </div> */}
          {/* <div className={'logo'}>
            <span className={'poll_dark'}>Poll Bridge</span>
            <div style={{ width: '130px', height: '10px', margin: 'auto', borderLeft: 'solid black 5px', borderRight: 'solid black 5px' }}>  
            </div>
          </div> */}
          {/* <div className={'logo'}>
            <span>pollbridge</span>
            <div style={{ width: '143px', height: '6px', margin: 'auto', borderLeft: 'solid var(--blue) 4px', borderRight: 'solid var(--blue) 4px' }}>  
            <div style={{ width: '124px', height: '6px', margin: 'auto', borderLeft: 'solid var(--blue) 4px', borderRight: 'solid var(--blue) 4px' }}>  
            <div style={{ width: '104px', height: '8px', margin: 'auto', borderLeft: 'solid var(--blue) 4px', borderRight: 'solid var(--blue) 4px' }}>  
            <div style={{ width: '84px', height: '11px', margin: 'auto', borderLeft: 'solid var(--blue) 4px', borderRight: 'solid var(--blue) 4px' }}>  
            <div style={{ width: '64px', height: '16px', margin: 'auto', borderLeft: 'solid var(--blue) 4px', borderRight: 'solid var(--blue) 4px' }}>  
            <div style={{ width: '44px', height: '11px', margin: 'auto', borderLeft: 'solid var(--blue) 4px', borderRight: 'solid var(--blue) 4px' }}>  
            <div style={{ width: '24px', height: '8px', margin: 'auto', borderLeft: 'solid var(--blue) 4px', borderRight: 'solid var(--blue) 4px' }}>  
            <div style={{ width: '5px', height: '6px', margin: 'auto', borderLeft: 'solid var(--blue) 4px', borderRight: 'solid var(--blue) 4px' }}>  
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
          </div> */}

          {/* <div className={'logo'}>
            <span>pollbridge</span>
            
            <div style={{ width: '152px', height: '16px', margin: 'auto', backgroundColor: '#888888', position: 'relative' }}>  
            <div style={{ width: '54px', height: '45px', 
              position: 'absolute', bottom: -34, left: 0, 
              borderTopRightRadius: '180px',
              backgroundColor: 'white' }}>  
            
            </div>

            <div style={{ width: '81px', height: '45px', 
              position: 'absolute', bottom: -34, left: 36,
              borderTopLeftRadius: '180px',
              borderTopRightRadius: '180px',
              backgroundColor: 'white' }}>  
            
            </div>

            <div style={{ width: '54px', height: '45px', 
              position: 'absolute', bottom: -34, right: 0, 
              borderTopLeftRadius: '180px',
              backgroundColor: 'white' }}>  
            
            </div>
            </div>
            
          </div> */}

          {/* <div className={'logo'}>
            <span>pollbridge</span>
            
            <div style={{ width: '152px', height: '16px', margin: 'auto', backgroundColor: 'black', position: 'relative' }}>  
            <div style={{ width: '54px', height: '45px', 
              position: 'absolute', bottom: -34, left: -27, 
              borderTopLeftRadius: '180px',
              borderTopRightRadius: '180px',
              backgroundColor: 'white' }}>  
            
            </div>

            <div style={{ width: '54px', height: '45px', 
              position: 'absolute', bottom: -34, left: 23,
              borderTopLeftRadius: '180px',
              borderTopRightRadius: '180px',
              backgroundColor: 'white' }}>  
            
            </div>

            <div style={{ width: '54px', height: '45px', 
              position: 'absolute', bottom: -34, left: 74,
              borderTopLeftRadius: '180px',
              borderTopRightRadius: '180px',
              backgroundColor: 'white' }}>  
            
            </div>

            <div style={{ width: '54px', height: '45px', 
              position: 'absolute', bottom: -34, right: -27, 
              borderTopLeftRadius: '180px',
              borderTopRightRadius: '180px',
              backgroundColor: 'white' }}>  
            
            </div>
            </div>
            
          </div> */}





          {/* <div className={'logo'}>
            <span className={'poll_dark'}>pollbridge</span>
          </div> */}
          {/* <div className={'logo'}>
            <span className={'poll_light'}>poll</span>
            <span className={'feedback'}>bridge</span>
          </div> */}
          {/* <div className={'logo'}>
            <span className={'feedback'}>pollbridge</span>
          </div> */}
          <br></br>
          <div>Welcome back!</div>
          <br></br>
          <br></br>
          <form onSubmit={this.onSignIn}>
            <input value={signInEmail} 
              className={signInEmail ? emailValid ? 'active_input max_width' : 'error_input max_width' : 'max_width'} type="email" placeholder='Email' name="email" 
              onChange={this.onEmailChange} />
            <br></br>
            <input value={signInPassword} className={signInPassword ? 'active_input max_width margin_top_5' : 'max_width margin_top_5'} type="password" placeholder='Password' name="password" onChange={this.onPasswordChange} />
            <br></br>
            <br></br>
            <button type="submit" className={'green_button'} disabled={disabled}>
              {signingIn ? 'loading...' : 'sign in'}
            </button>

          </form>
          <br></br>
          <div className={'red_font'}>{signInError}</div>
          <br></br>
          <div>Don't have an account yet? <span onClick={this.signUpClick} className={'link'}>Sign up</span></div>
          <br></br>
          <div onClick={this.forgotPasswordClick} className={'link'}>Forgot password?</div>
          {/* <div style={{ margin: 'auto', border: 'solid black 2px' }}> */}
          <br></br>
          <br></br>
          <div className={'margin_auto'}>
            <img src={lady_chair_laptop} height="200" alt="logo" />
          </div>
          {/* </div> */}
        </div>
      
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);